import React from 'react';
import classNames from 'classnames';
import styles from './index.module.css';
import Autolinker from 'autolinker';
import bachelorUser from '../../../assets/bachelorUser.png';
//
// Shows partner's text bubble including chat messages from Bachelor. Appears to the left of screen.
// -----------------------
const PartnerTextMessage = ({
  avatar,
  message,
  shouldBlurIcon,
  createDate,
  createTime,
  isWhiteMode
}) => {
  const htmlMessage = Autolinker.link(message, {
    stripPrefix: false
  });

  return (
    <span
      className={classNames(styles.partnerTextMessage, {
        [styles.partnerTextShowTime]: createTime,
        [styles.partnerTextShowDate]: createDate
      })}
    >
      <img
        alt=""
        src={avatar ? avatar : bachelorUser}
        className={classNames({
          [styles.chatUserIcon]: true,
          [styles.blur]: shouldBlurIcon
        })}
      />
      <div
        className={classNames(styles.chatPartnerTextBubble, {
          [styles.textBlack]: isWhiteMode
        })}
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
      />
      {createDate ? (
        <div className={styles.chatPartnerCreateDate}>{createDate}</div>
      ) : null}
      {createTime ? (
        <div className={styles.chatPartnerCreateTime}>{createTime}</div>
      ) : null}
    </span>
  );
};

export default PartnerTextMessage;
