import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from 'antd-mobile';
import classNames from 'classnames';

import styles from './index.module.css';
import { ReactComponent as MoonIcon } from '@assets/moon.svg';
import { ReactComponent as SunIcon } from '@assets/sun.svg';

class NavBarMain extends React.PureComponent {
  state = {};

  render() {
    const {
      userAvatar,
      shouldBlurIcon,
      isWhiteMode,
      isLunchTime,
      dateTimeText
    } = this.props;

    if (userAvatar) {
      return (
        <NavBar
          className={classNames(
            styles.navBar,
            isWhiteMode ? styles.navBarWhite : '',
            this.props.customClassName
          )}
          leftContent={this.props.leftContent}
          rightContent={this.props.rightContent}
          onLeftClick={this.props.onLeftClick}
        >
          <div className={styles.chatNavTitle}>
            <div
              className={classNames(
                styles.navNickname,
                isWhiteMode ? styles.navNicknameWhite : ''
              )}
            >
              <span
                style={{
                  marginRight: '5px'
                }}
              >
                {isLunchTime ? <SunIcon /> : <MoonIcon />}
              </span>
              {dateTimeText}
            </div>
          </div>
        </NavBar>
      );
    }

    if (this.props.innerRef) {
      return (
        <div ref={this.props.innerRef}>
          <NavBar
            className={classNames(
              styles.navBar,
              isWhiteMode ? styles.navBarWhite : '',
              this.props.customClassName
            )}
            leftContent={this.props.leftContent}
            rightContent={this.props.rightContent}
            onLeftClick={this.props.onLeftClick}
          >
            {this.props.title}
          </NavBar>
        </div>
      );
    }

    return (
      <NavBar
        className={classNames(
          styles.navBar,
          isWhiteMode ? styles.navBarWhite : '',
          this.props.customClassName
        )}
        leftContent={this.props.leftContent}
        rightContent={this.props.rightContent}
        onLeftClick={this.props.onLeftClick}
      >
        {this.props.children}
        <span>{this.props.title}</span>
      </NavBar>
    );
  }
}

NavBarMain.propTypes = {
  title: PropTypes.string,
  rightContent: PropTypes.array,
  leftContent: PropTypes.array,
  icon: PropTypes.element,
  onLeftClick: PropTypes.func,
  onUserAvatarClick: PropTypes.func,
  customClassName: PropTypes.string,
  titleClassName: PropTypes.string
};

NavBarMain.defaultProps = {
  title: '',
  rightContent: [],
  leftContent: [],
  icon: null,
  onLeftClick: () => {},
  customClassName: undefined
};

export default NavBarMain;
export { default as BackArrowIcon } from './BackArrowIcon';
