import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getCards(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_CARDS, API.GET, action.params);

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_CARDS_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_CARDS_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_CARDS_FAILED, error });
  }
}

function* createCard(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_CARDS + '/store',
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CREATE_CARD_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.CREATE_CARD_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CREATE_CARD_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* setDefaultCard(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_CARDS + `/set-default/${action.params.cardId}`,
      API.PUT
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SET_DEFAULT_CARD_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.SET_DEFAULT_CARD_FAIL, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.SET_DEFAULT_CARD_FAIL, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetCards() {
  yield takeLatest(types.GET_CARDS_REQUESTED, getCards);
}

export function* watchCreateCard() {
  yield takeLatest(types.CREATE_CARD_REQUESTED, createCard);
}

export function* watchSetDefaultCard() {
  yield takeLatest(types.SET_DEFAULT_CARD_REQUESTED, setDefaultCard);
}
