import React from 'react';
import { pipe, pathOr, pick } from 'ramda';
import { UserGender } from '@constants/constant';
import ja from 'date-fns/locale/ja';
import { format } from 'date-fns';
import moment from 'moment';

export function renderMessagesWithBreakpoints(messages) {
  const messagesSplit = messages.split('\n');
  const maxLength = messagesSplit.length;
  return messagesSplit.map((msg, index) => {
    if (msg.length && index + 1 === maxLength) {
      return msg;
    }
    if (msg.length) {
      return (
        <>
          {msg}
          <br />
        </>
      );
    }
    return <br />;
  });
}

/**
 * Converts integer into a string and prepends 0
 * when the value is less than 10.
 * ex: doubleDigits(1) // '01'
 *     doubleDigits(8) // '08'
 **/
export function doubleDigits(num) {
  return num < 10 ? `0${num}` : `${num}`;
}

/**
 * Return's the user's participation dates from the homescreen data API
 * @param {Object} homescreen
 * @return {Object} { participate_saturday: xxx, participate_sunday: xxx }
 **/
export const getParticipationDatesFromHomescreen = pipe(
  pathOr({}, ['participation']),
  pick(['participate_saturday', 'participate_sunday'])
);

export function getZendeskFaqUrl(userGender) {
  if (userGender === UserGender.MALE) {
    return `https://bachelorapp.zendesk.com/hc/ja/categories/4408155186841`;
  } else {
    return `https://bachelorapp.zendesk.com/hc/ja/categories/4408155187865`;
  }
}

export function getZendeskNewTicketUrl(userId) {
  return `https://bachelorapp.zendesk.com/hc/ja/requests/new?uref=${userId}&new`;
}

export function getZendeskHelpPageUrl(userId) {
  return `https://bachelorapp.zendesk.com/hc/ja/articles/4404707677209?uref=${userId}&new`;
}

export function getZendeskRematching3pmUrl(userId) {
  return `https://bachelorapp.zendesk.com/hc/ja/articles/4404733286041-%E4%B8%8D%E5%85%B7%E5%90%88-%E3%83%90%E3%82%B0%E3%81%8C%E7%99%BA%E7%94%9F%E3%81%97%E3%81%9F?uref=${userId}&new`;
}

export function getPlanUrl() {
  return `https://wi.bachelorapp.net/new-c-plans `;
}

export function getZendeskBacherlorRate(userGender) {
  return userGender === UserGender.MALE
    ? `https://bachelorapp.zendesk.com/hc/ja/articles/4404732994201?new`
    : `https://bachelorapp.zendesk.com/hc/ja/articles/4404733342361?new`;
}

export function getZendeskBacherlorNotRenew(userGender) {
  return userGender === UserGender.MALE
    ? `https://bachelorapp.zendesk.com/hc/ja/articles/4404732984089?new`
    : `https://bachelorapp.zendesk.com/hc/ja/articles/4404741274905?new`;
}

export function getPlanCostUrl() {
  return 'https://www.wi.bachelorapp.net/new-io-premium-plan-cost';
}

export function openZendeskFaqUrl(userGender) {
  const zendeskUrl = getZendeskFaqUrl(userGender);
  window.open(zendeskUrl);
}

export function openZendeskHelpPageUrl(userId) {
  const zendeskUrl = getZendeskHelpPageUrl(userId);
  window.open(zendeskUrl);
}

export function openZendeskHelpRematching3pmPageUrl(userId) {
  const zendeskUrl = getZendeskRematching3pmUrl(userId);
  window.open(zendeskUrl);
}

export function openPlanPage() {
  const plankUrl = getPlanUrl();
  window.open(plankUrl);
}

export function openPlanCostPage() {
  const plankUrl = getPlanCostUrl();
  window.open(plankUrl);
}

// param: date (MM/DD)
// returns: string
export function convertFormatDateToJapanese(date) {
  let result = '';
  if (date) {
    let dateFormat = moment(date, 'MM/DD');
    result = format(dateFormat, 'MMMDo(dd)', { locale: ja });
  }

  return result;
}

export function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
}

export function convertMapIntoArray(item) {
  let resultFactorConvert = Object.keys(item).map(function(key) {
    return item[key];
  });

  if (resultFactorConvert.length > 3) {
    resultFactorConvert = resultFactorConvert.slice(0, 3);
  }

  return resultFactorConvert;
}

export function isEmptyString(string) {
  return !string || string == '';
}

export const formatDataLabel = (
  data = [],
  specialTitleBegin = '',
  specialTitleEnd = ''
) =>
  data.map(item => ({
    label:
      item.register_options_translations[0] &&
      bindingLabel(
        item.value,
        item.register_options_translations[0].display_name,
        data[0].value,
        specialTitleBegin,
        data[data.length - 1].value,
        specialTitleEnd
      ),
    value: item.value
  }));

export const bindingLabel = (
  value,
  label,
  begin = '',
  specialTitleBegin = '',
  end = '',
  specialTitleEnd = ''
) => {
  if (begin && specialTitleBegin && begin == value) {
    return label + specialTitleBegin;
  } else if (end && specialTitleEnd && end == value) {
    return label + specialTitleEnd;
  } else {
    return label;
  }
};

export function getUserAgeFromDob(dateOfBirth) {
  const diffMs = Date.now() - new Date(dateOfBirth).getTime();
  const age = new Date(diffMs);

  return Math.abs(age.getUTCFullYear() - 1970);
}

export function suggestionChangePlanUrl(plan, contract_term) {
  window.open(
    window.location.origin +
      `/profile?discount_type=no_discount&cost_plan=${plan}&contract_term=${contract_term}`
  );
}
