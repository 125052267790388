import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* getUserGlobalInfo(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.API_GLOBAL_USER, API.GET);
    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_USER_GLOBAL_INFO_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_USER_GLOBAL_INFO_FAILD, error: response });
    }

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_USER_GLOBAL_INFO_FAILD, error });
  }
}

export function* watchGetUserGlobalInfo() {
  yield takeLatest(types.GET_USER_GLOBAL_INFO_REQUESTED, getUserGlobalInfo);
}
