import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getUserProfile(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_GET_PROFILE_INFO,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.USER_PROFILE_SUCCEEDED, data: response.data });
    } else {
      yield put({ type: types.USER_PROFILE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.USER_PROFILE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* updateProfileInfo(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_UPDATE_PROFILE,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.UPDATE_PROFILE_SUCCEEDED, data: response.data });
    } else {
      yield put({ type: types.UPDATE_PROFILE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_PROFILE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* updatePreferences(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_UPDATE_PREFERENCE,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_PREFERENCES_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.UPDATE_PREFERENCES_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_PREFERENCES_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getPreferencesInfo(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_GET_PREFERENCE,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_PREFERENCES_INFO_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_PREFERENCES_INFO_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_PREFERENCES_INFO_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getPlanSubscription(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_GET_PLAN_SUBSCRIPTION,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_PLAN_SUBSCRIPTION_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.GET_PLAN_SUBSCRIPTION_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_PLAN_SUBSCRIPTION_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetPlanSubscription() {
  yield takeLatest(types.GET_PLAN_SUBSCRIPTION_REQUESTED, getPlanSubscription);
}

export function* watchUpdateProfileInfo() {
  yield takeLatest(types.UPDATE_PROFILE_REQUESTED, updateProfileInfo);
}

export function* watchGetUserProfile() {
  yield takeLatest(types.USER_PROFILE_REQUESTED, getUserProfile);
}

export function* watchUpdatePreferences() {
  yield takeLatest(types.UPDATE_PREFERENCES_REQUESTED, updatePreferences);
}

export function* watchGetPreferencesInfo() {
  yield takeLatest(types.GET_PREFERENCES_INFO_REQUESTED, getPreferencesInfo);
}
