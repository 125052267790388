import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';
import GTagManager from 'react-gtm-module';
import { path } from 'ramda';
import { store } from '../redux/store';
import { gtmID, gaID } from '../constants/constant';

GoogleAnalytics.initialize(window.__env__.GOOGLE_ANALYTICS_TRACKING_ID || gaID);
GTagManager.initialize({
  gtmId: window.__env__.GOOGLE_TAG_MANAGER_ID || gtmID
});

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = ({ page, userId }) => {
    // Set Google Analytics
    GoogleAnalytics.set({
      page: page,
      userId: userId || null,
      ...options
    });
    GoogleAnalytics.pageview(page);

    // Set Google Tag Manager
    GTagManager.dataLayer({
      dataLayer: {
        userId: userId || null
      }
    });
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      // grab current state
      const state = store.getState();
      const userId = path(
        ['auth', 'userdata', 'userAuth', 'user', 'id'],
        state
      );
      trackPage({ page, userId });
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const state = store.getState();
      const currentUserId = path(
        ['auth', 'userdata', 'userAuth', 'user', 'id'],
        state
      );
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage({ page: nextPage, userId: currentUserId });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
