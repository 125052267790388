import React from 'react';
import './index.css';
import classnames from 'classnames';
import classNames from 'classnames';
import styles from '../../containers/PartnerDetail/index.module.css';

class Popup extends React.PureComponent {
  handleCancel = () => {
    const { onCancelClicked } = this.props;
    if (onCancelClicked) {
      onCancelClicked();
    }
  };

  handleOkClicked = () => {
    const { onOkClicked } = this.props;
    if (onOkClicked) {
      onOkClicked();
    }
  };

  handlePositiveClicked = () => {
    const { onPositiveClicked } = this.props;
    if (onPositiveClicked) {
      onPositiveClicked();
    }
  };

  handleOtherPositiveClicked = () => {
    const { onOtherPositiveClicked } = this.props;
    if (onOtherPositiveClicked) {
      onOtherPositiveClicked();
    }
  };

  handleNegativeClicked = () => {
    const { onNegativeClicked } = this.props;
    if (onNegativeClicked) {
      onNegativeClicked();
    }
  };

  handleNeutralClicked = () => {
    const { onNeutralClicked } = this.props;
    if (onNeutralClicked) {
      onNeutralClicked();
    }
  };

  renderTitle() {
    const { title, titleClassName, TitleComponent } = this.props;
    if (TitleComponent) {
      return TitleComponent;
    }
    if (title) {
      return (
        <div className="title-container">
          <div className={titleClassName || 'title title--pre-line'}>
            {title}
          </div>
        </div>
      );
    }
    return null;
  }

  renderFooter() {
    const { footer, footerClassName, FooterComponent } = this.props;
    if (FooterComponent) {
      return FooterComponent;
    }
    if (footer) {
      return (
        <div className="footer-container">
          <div className={footerClassName || 'footer'}>{footer}</div>
        </div>
      );
    }
    return null;
  }

  renderMessages() {
    const {
      messages,
      MessagesComponent,
      messageStyle,
      messageClassName,
      messagesContainerClassName = 'message-container',
      messageWithBreak,
      messageBold,
      messagesCouponExpiration,
      contentTextLeft = false,
      detailInfoLink = '',
      detailInfoText = '',
      textLeftMsg = false
    } = this.props;
    if (MessagesComponent) {
      return MessagesComponent;
    }
    if (messages && messages.length > 0) {
      var messageItemClass = 'message';
      messageItemClass +=
        messages.length === 1 ? ' message-one-line' : ' message-multiple-lines';

      if (textLeftMsg) {
        messageItemClass += ' text-left';
      }

      if (messageClassName) {
        messageItemClass = messageClassName;
      }

      return (
        <div className={messagesContainerClassName}>
          {messages.map((element, i) => {
            if (element) {
              // Add line-break for not last message
              if (messageWithBreak && messages.length !== i + 1) {
                return (
                  <div
                    key={i}
                    className={messageItemClass}
                    // className={messageItemClass + ' message-line-break'}
                    style={messageStyle}
                  >
                    {this.renderElement(element)}
                  </div>
                );
              }

              return (
                <div
                  key={i}
                  className={`${messageItemClass} ${
                    contentTextLeft ? 'content-text-left' : ''
                  }`}
                  style={messageStyle}
                >
                  {typeof element === 'string'
                    ? this.renderElement(element)
                    : element}
                </div>
              );
            }
            return null;
          })}
          {detailInfoLink && detailInfoText && (
            <div className={styles.detailInfoLinkGroup}>
              <div
                className={classNames(
                  styles.txtTitle,
                  styles.txtTitleRight,
                  styles.detailInfoLinkBtn
                )}
                onClick={e => {
                  window.open(detailInfoLink);
                }}
              >
                {detailInfoText}
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  }

  renderElement = element => {
    if (element.includes('水曜: 18時半ごろ')) {
      return (
        <div className={'message-row-line'}>
          <div className={'message-bold'}>水曜:</div>
          <div style={{ marginLeft: 8 }}>18時半ごろ</div>
        </div>
      );
    } else if (element.includes('土/日曜: 17時ごろ')) {
      return (
        <div className={'message-row-line'}>
          <div className={'message-bold'}>土/日曜:</div>
          <div style={{ marginLeft: 8 }}>17時ごろ</div>
        </div>
      );
    } else if (element.includes('予約変更、取消しはデート')) {
      return (
        <div
          className="row message-row-line-margin"
          dangerouslySetInnerHTML={{
            __html:
              '<p>予約変更、取消しは<p style="color: red">デート2日前</p>まで可能<p>です。</p></p>'
          }}
        />
      );
    } else {
      return element;
    }
  };

  renderOkButton(isWhiteMode) {
    const buttonLabel = this.props.okButtonTitle;
    const buttonClass = this.props.okButtonClass;
    if (buttonLabel) {
      return (
        <div
          className={classNames({
            ['button button-ok ' + (buttonClass ? buttonClass : '')]: true,
            'text-white': isWhiteMode,
            'button-white-mode': isWhiteMode
          })}
          onClick={this.handleOkClicked}
        >
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderPositiveButton(isWhiteMode) {
    const buttonLabel = this.props.positiveButtonTitle;
    const { positiveButtonClassName } = this.props;
    const className = positiveButtonClassName || 'button button-positive';
    if (buttonLabel) {
      return (
        <div
          className={classNames({
            [className]: true,
            'text-white': isWhiteMode,
            'button-white-mode': isWhiteMode
          })}
          onClick={this.handlePositiveClicked}
        >
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderOtherPositiveButton(isWhiteMode) {
    const buttonLabel = this.props.positiveOtherButtonTitle;
    const { positiveButtonClassName } = this.props;
    const className = positiveButtonClassName || 'button button-positive';
    if (buttonLabel) {
      return (
        <div
          className={classNames({
            [className]: true,
            'text-white': isWhiteMode
          })}
          onClick={this.handleOtherPositiveClicked}
        >
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderNegativeButton(isWhiteMode) {
    const buttonLabel = this.props.negativeButtonTitle;
    const { negativeButtonClassName } = this.props;
    const className = negativeButtonClassName || 'button button-negative';
    if (buttonLabel) {
      return (
        <div
          className={classNames({
            [className]: true,
            'text-white': isWhiteMode
          })}
          onClick={this.handleNegativeClicked}
        >
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderNeutralButton(isWhiteMode) {
    const buttonLabel = this.props.neutralButtonTitle;
    if (buttonLabel) {
      const buttonClass = this.props.neutralButtonClass || '';
      return (
        <div
          className={classNames({
            ['button button-neutral' +
            (buttonClass ? ' ' + buttonClass : '')]: true,
            'text-white': isWhiteMode,
            'button-white-mode': isWhiteMode
          })}
          onClick={this.handleNeutralClicked}
        >
          <div>{buttonLabel}</div>
        </div>
      );
    }
    return null;
  }

  renderButtons(isWhiteMode) {
    const { ButtonComponent } = this.props;
    if (ButtonComponent) {
      return ButtonComponent;
    }
    return (
      <div className="button-container">
        {this.renderOtherPositiveButton(isWhiteMode)}
        {this.renderPositiveButton(isWhiteMode)}
        {this.renderNegativeButton(isWhiteMode)}
        {this.renderNeutralButton(isWhiteMode)}
        {this.renderOkButton(isWhiteMode)}
        {this.renderFooter()}
        {this.props.renderBottomButton && this.props.renderBottomButton()}
      </div>
    );
  }

  renderCloseButton(isWhiteMode) {
    const { disableClose } = this.props;
    if (disableClose) {
      return null;
    }
    return (
      <div className="close-button-container" onClick={this.handleCancel}>
        <div
          className={classNames({
            'close-button': true,
            'button-white-mode': isWhiteMode
          })}
        />
      </div>
    );
  }

  render() {
    if (!this.props.visible) {
      document.body.classList.remove('popup-open');
      return null;
    }
    document.body.classList.add('popup-open');

    const {
      headerClassName = '',
      footerClassName = 'footer',
      contentClassName = null,
      contentStyle = 'content',
      hyperLink,
      hyperLinkCustomText = '',
      hyperLinkCustomClass = '',
      hyperLinkCustomClick = () => {},
      customPopup = '',
      detailInfoLink = '',
      detailInfoText = ''
    } = this.props;
    const isWhiteMode = JSON.parse(localStorage.getItem('isWhiteMode'));
    return (
      <div>
        <div className={`popup ${customPopup}`}>
          <div className="popup-dialog popup-centered">
            <div className={`popup-content`}>
              <div className="popup-body">
                <div className={`header ${headerClassName}`}>
                  {this.renderTitle()}
                </div>
                {this.renderMessages() ? (
                  <div
                    className={classnames(
                      contentClassName,
                      contentStyle,
                      detailInfoLink && detailInfoText
                        ? 'content-has-bottom-link'
                        : ''
                    )}
                  >
                    {this.renderMessages()}
                  </div>
                ) : null}
                {hyperLink ? (
                  hyperLinkCustomText ? (
                    <div
                      className={
                        'hyper-link custom-hyper-link hyper-link-bold ' +
                        hyperLinkCustomClass
                          ? hyperLinkCustomClass
                          : ''
                      }
                      onClick={hyperLinkCustomClick}
                    >
                      {hyperLinkCustomText}
                    </div>
                  ) : (
                    <div className="hyper-link">
                      マッチングの詳しい仕組みはこちら＞
                    </div>
                  )
                ) : null}
                <div className={footerClassName}>
                  {!this.props.notShowButton && this.renderButtons(isWhiteMode)}
                </div>
                {!this.props.notShowCloseButton &&
                  this.renderCloseButton(isWhiteMode)}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </div>
    );
  }
}

Popup.propTypes = {};

Popup.defaultProps = {};

export default Popup;
