import { API } from '../redux/services/api';

export const HttpCode = {
  SUCCESS: 200,
  UNAUTHORIZED: 401
};

export const StorageKey = {
  SOCIAL_ID: '5LOs0kIiq6M5upU',
  LOGIN_TYPE: 'KyDey0IBFZdG6PL',
  LOGIN_MIGRATING: 'uEvxKOFyfpVVxPF',
  REOPEN: 'REOPEN'
};

export const LpQueryStr = {
  LOCAL_STORAGE_KEY: 'lp_query_str'
};

export const PaymentGateway = {
  STRIPE: 'stripe',
  APPLE_PAY: 'apple_pay'
};

export const MonthData = [
  { value: '1', label: 1 },
  { value: '2', label: 2 },
  { value: '3', label: 3 },
  { value: '4', label: 4 },
  { value: '5', label: 5 },
  { value: '6', label: 6 },
  { value: '7', label: 7 },
  { value: '8', label: 8 },
  { value: '9', label: 9 },
  { value: '10', label: 10 },
  { value: '11', label: 11 },
  { value: '12', label: 12 }
];

export const Weeks = {
  THIS_WEEK: 'THIS_WEEK',
  NEXT_WEEK: 'NEXT_WEEK',
  NEXT_NEXT_WEEK: 'NEXT_NEXT_WEEK'
};

export const AuthType = {
  MOBILE: 'MOBILE',
  LINE: 'LINE',
  FACEBOOK: 'FACEBOOK'
};

export const ResponseStatus = {
  FAILURE: 0,
  SUCCESS: 200,
  VALIDATEFAIL: 422,
  // SUCCESS: 1,
  FORCEFULLY: 9,
  NEW_USER: 8,
  DEACTIVE_USER: 5,
  PROFILE_AWAITING_USER: 2,
  INCOMPLETE_PROFILE_USER: 1,
  DOCCUMENT_REQUIRED: 10,
  CANCEL_USER: 6,
  TEMPORARY_CANCEL: 11,
  PERMANENT_CANCEL: 12,
  LOCK_USER: 7,
  VALIDATION_ERROR: 101,
  FEEDBACK_VIEW: 301,
  COUPON_VALIDATION_UNPARTICIPATION: 12,
  FEEDBACK_NOT_GIVEN: 13,
  PROFILE_MISSING_EMAIL: 14
};

export const UserStatus = {
  INCOMPLETE_PROFILE: 1,
  AWAITING_SCREENING: 2,
  VERIFIED_BY_ADMIN: 3,
  APPROVED_BY_SYSTEM: 4,
  DEACTIVATED: 5,
  CANCELLED_PROFILE: 6
};

export const PreferencesComplete = {
  LAST_STEP_FIRST_FORM: 7,
  LAST_STEP_SECOND_FORM: 16
};

export const ParticipationAvailability = {
  NONE: 'none',
  All: 'all',
  OncePerWeek: 'once_per_week',
  OncePerThreeWeeks: 'once_per_three_weeks'
};

export const WeekPlan = {
  WEEKDAY: 'weekday',
  WEEKEND: 'weekend'
};

export const CostPlan = {
  LIGHT: 'light',
  NORMAL: 'normal',
  PREMIUM: 'premium'
};

export const ContractTerm = {
  ONE_MONTH: 1,
  THREE_MONTH: 3,
  SIX_MONTH: 6,
  TWELVE_MONTH: 12
};

export const TrialStatusOrPaid = {
  TRIAL: 'Trial',
  PAID: 'Paid',
  TEMP_CANCELLED: 'TempCancelled'
};

export const TrialStatus = {
  ACTIVE: 10,
  TEMP_CANCELLED: 20,
  COMPLETED: 30
};

export const ParticipantsStatus = {
  Awaiting: 10,
  Unmatched: 20,
  Matched: 30,
  Canceled: 40
};

export const DatingEvent = {
  CANCELLED: 1,
  LOCATION_CHANGED: 2
};

export const ExchangeCoupon = {
  NUMBER_BOTH_DATE_EXCHANGE: 3
};

export const CouponStatus = {
  USED: 1,
  UN_USED: 2,
  EXCHANGED: 4,
  EXPIRED: 5
};

export const CouponType = {
  DATING: 'dating',
  BACHELOR: 'bachelor_coupon'
};

export const UserGender = {
  MALE: 1,
  FEMALE: 2,
  BOTH: 3
};

export const DateTimeType = {
  CLEAR_GRAY: 'clear_gray',
  DARK_GRAY: 'dark_gray'
};

export const DESCRIPTION_TYPE = {
  //incomplete form 2 and show_sample_date = 1
  CONFIRM_BUTTON: 'confirm_button',

  //no dating, show_sample_date = 1
  SAMPLE_DATE: 'sample_date',

  //Set start_at in table dating as same as dating_date in dating_day
  CHAT_BUTTON: 'chat_button',

  //step = 16, no dating, status = 10, show_sample_date = 0, no rematching record
  AWAITING_MATCHING: 'awaiting_matching',

  //step = 16, no dating, status = 20, show_sample_date = 0, has rematching record
  AWAITING_RE_MATCHING: 'awaiting_re_matching',

  AWAITING_RE_MATCHING_FOR_LUNCH: 'awaiting_re_matching_for_lunch',

  UN_REMATCHING_7AM: 'unmatched_lunch_date',
  //has dating and dating status = 1
  PARTNER_DETAIL: 'partner_detail',

  //dating status = 2 and has rematching record
  PARTNER_CANCELLED: 'partner_cancelled',

  //dating status = 2 and no rematching record
  DATE_CANCELLED: 'date_cancelled'
};

export const RegistrationFormKey = {
  DRINK: 'drink',
  DIVORCE: 'divorce',
  SMOKE: 'smoke',
  INCOME: 'income',
  LIMIT_MIN_AGE: 20,
  LIMIT_MALE_MAX_AGE: 50,
  LIMIT_FEMALE_MAX_AGE: 45,
  MIN_RANGE_AFTER_40_NEED_PREMIUM: 6,
  AGE_NEED_TO_CHECK_PREMIUM: 40,
  HEIGHT_MINIUM_MALE: 160,
  HEIGHT_MINIUM_FEMALE: 170,
  MINIMUM_AGE_DISTANCE: 8,
  MINIMUM_HEIGHT_DISTANCE: 10,
  DEFAULT_HEIGHT_TO_VALUE: 999,
  DEFAULT_HEIGHT_FROM_VALUE: 0,
  DEFAULT_AGE_TO_VALUE: 99,
  DEFAULT_AGE_FROM_VALUE: 0,
  DEFAULT_BODY_SHAPE_TO_VALUE: 99,
  MINIMUM_BODY_SHAPE_DISTANCE: 1,
  MINIMUM_BODY_SHAPE_DISTANCE_NOT_CARE: 2,
  DEFAULT_BODY_SHAPE_FROM_VALUE: 0
};

export const CARD_CONSTANTS = {
  MIN_LENGTH: 14,
  MAX_LENGTH: 16,
  CVC_LENGTH: 3
};

export const ParticipationOpenExpireStatus = {
  CLOSED: 0,
  OPENED: 1,
  EXPIRED: 2
};

export const FeedbackPointType = {
  BAD: 1,
  GOOD: 2
};

export const RECOMMENDATION = {
  AWAITING_CANCEL: 'awaiting_cancel',
  RECOMMEND: 'recommend'
};

export const SUBSCRIPTION_STATUS = {
  Active: 'active',
  Canceled: 'canceled',
  Incomplete: 'incomplete'
};

export const FeedbackType = {
  FACE: '容姿/雰囲気',
  PERSONALITY: '性格・会話',
  BEHAVIOR: '振る舞い'
};

export const MatchDetails = {
  STATUS_NO_ANY_DATE: 0,
  STATUS_DATING_MATCHED_CONFIRM: 1,
  STATUS_DATING_MATCHED_CANCELLED: 2,
  STATUS_DATING_MATCHED_COMPLETED: 3
};

export const RequestChangeTime = {
  STATUS_REQUEST_TIME_PENDING_DELAY: 0,
  STATUS_REQUEST_TIME_PENDING: 1,
  STATUS_REQUEST_TIME_CANCEL: 2,
  STATUS_REQUEST_TIME_REJECT: 3,
  STATUS_REQUEST_TIME_APPROVED: 4
};

export const MatchProfile = {
  POPUP_PARTNER_NOT_FOUND: 1,
  POPUP_HOW_TO_BE_MATCHED: 2,
  POPUP_DATE_DECIDED: 3,
  POPUP_PARTNER_CANCELLED: 4,
  POPUP_PARTNER_CHANGED_LOCATION: 5
};

export const GlobalPopup = {
  CHANGE_COST_PLAN: 6,
  CHANGE_COST_PLAN_DOWNGRADE: 7,
  CHANGE_COST_PLAN_UPGRADE: 8,
  COMPLETE_UPDATE_PROFILE: 9
};

export const CancelDateByMeOption = {
  // Constants for cancel option in cancelled date by me api
  INFORMED_PARTNER: 1,
  DID_NOT_SHOW_UP_PARTNER: 2
};

export const CancelDateByPartnerOption = {
  INFORMED_ME: 1,
  DID_NOT_SHOW_UP: 2
};

export const CancellationChargeLink = {
  MALE: 'https://bachelorapp.zendesk.com/hc/ja/articles/4404707677209?new',
  FEMALE: 'https://bachelorapp.zendesk.com/hc/ja/articles/4404733266073?new'
};

export const RematchUnderlineClickLink = {
  MALE: 'https://bachelorapp.zendesk.com/hc/ja/articles/4404707677209?new',
  FEMALE: 'https://bachelorapp.zendesk.com/hc/ja/articles/4404733266073?new'
};

export const ChatFeature = {
  USER_TYPE_BASE_USER: 'baseUser',
  USER_TYPE_PARTNER_USER: 'partnerUser',
  USER_TYPE_CAFE_INFO: 'cafeInfo',
  USER_TYPE_CAFE_SUGGESTION: 'cafeSuggestion',
  USER_TYPE_PARK_SUGGESTION: 'parkSuggestion',
  USER_TYPE_VIDEO_SUGGESTION: 'videoSuggestion',
  USER_TYPE_CAFE_SUGGESTION_LINK: 'cafeSuggestionLink',
  USER_TYPE_VIDEO_SUGGESTION_LINK: 'videoSuggestionLink',
  LOCATION_CATEGORY_CAFE: 'cafe',
  LOCATION_CATEGORY_PARK: 'park'
};

export const ErrorFeedBack = {
  FACE: 'face',
  FACE_GOOD: 'face_good',
  FACE_BAD: 'face_bad',
  PERSONALITY: 'personality',
  PERSONALITY_GOOD: 'personalityGood',
  PERSONALITY_BAD: 'personalityBad',
  BEHAVIOR_GOOD: 'behavior_good',
  BEHAVIOR: 'behavior',
  BODY_SHAPE: 'body_shape',
  BACHELOR_SUITABLE: 'bachelor-suitable',
  SATISFACTION: 'satisfaction',
  BEHAVIOR_BAD: 'behavior_bad'
};

export const redirectFB = () => {
  window.location.replace(API.BASE_URL + 'social-login?redirect_url=app-login');
};
export const redirectReopenForm = nextAction => {
  window.location.replace(API.BASE_URL + 'reopen-form');
};
export const redirectSmsLogin = nextAction => {
  window.location.replace(
    API.BASE_URL +
      `sms-portal?redirect_url=app-login${
        nextAction ? '&action=' + nextAction : ''
      }`
  );
};
export const redirectUserProfile = () => {
  window.location.replace(API.BASE_URL + 'user-profile');
};
export const redirectUserProfileStep2 = () => {
  window.location.replace(API.BASE_URL + 'user-profile?edit=1&step=2');
};
export const redirectLINELogin = nextAction => {
  window.location.replace(API.BASE_URL + `api/v2/auth/login/line`);
};
export const redirectFacebookLogin = nextAction => {
  window.location.replace(API.BASE_URL + `api/v2/auth/login/facebook`);
};
export const redirectLINE = (socialLoginLink, userId) => {
  window.location.replace(
    `${socialLoginLink}&id=${userId}&redirect_url=${encodeURIComponent(
      API.BASE_URL + 'social-login?redirect_url=line-login'
    )}`
  );
};

export const redirectUnderScreeningMaleUser = () => {
  window.location.replace('https://wi.bachelorapp.net/usc-male');
};

export const redirectSMS = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/requests/new?ticket_form_id=900000026183&uref=${userId}&email=${email}&new`
  );
};

export const redirectNewSMS = (userId, language_id) => {
  window.location.replace(
    `${API.BASE_URL}phoneNumber?uref=${userId}&language_id=${language_id}`
  );
};

export const redirectUnderScreeningFemaleUser = () => {
  window.location.replace('https://wi.bachelorapp.net/usc-female');
};

export const redirectSuspendCannotConfirmMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/900000527003?uref=${userId}&email=${email}&new`
  );
};

export const redirectSuspendCannotConfirmFeMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/900000534246?uref=${userId}&email=${email}&new`
  );
};

export const redirectSuspendMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/900000514483?uref=${userId}&email=${email}&new`
  );
};

export const redirectSuspendFeMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/900000518406?uref=${userId}&email=${email}&new`
  );
};

export const redirectSuspendCancelMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/360033268632-%E4%BC%91%E4%BC%9A%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6?uref=${userId}&email=${email}&new`
  );
};

export const redirectSuspendCancelFeMale = (userId, email) => {
  window.location.replace(
    `https://bachelorapp.zendesk.com/hc/ja/articles/360033641111-%E4%BC%91%E4%BC%9A%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6?uref=${userId}&email=${email}&new`
  );
};

export const redirectVideoDateSuggestion = userGender => {
  let link = `https://bachelorapp.zendesk.com/hc/ja/articles/4404707718681?new`;
  if (userGender === UserGender.FEMALE) {
    link = `https://bachelorapp.zendesk.com/hc/ja/articles/4404741225369?new`;
  }
  window.open(link);
};

export const reopenValue = {
  TRIAL_MALE: 'trial_male',
  AWAITING_FOR_SCREENING_MALE: 'awaiting_for_screening_male',
  AWAITING_FOR_SCREENING_FEMALE: 'awaiting_for_screening_female'
};

export const Colors = {
  LINE: '#00B900',
  TWITTER: '#1DA1F2',
  MAIN_GOLD: '#C0A76E',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  GRAY: '#C4C4C4',
  PRIMARY_COLOR: '#C89C34'
};

export const rematchParticipantType = {
  NotParticipatedYet: 'not_participated_yet',
  AlreadyParticipated: 'already_participated', // return as error type from BE
  AfterRematchTime: 'after_rematch_time', // return as error type from BE
  NotAllowedUser: 'not_allowed_user' // return as error type from BE
};

// Facebook conversions API request version
export const fbApiVersion = 'v10.0';

export const FacebookPixelEventName = {
  PageView: 'PageView',
  CompleteRegistration: 'CompleteRegistration',
  SubmitApplication: 'SubmitApplication',
  StartTrial: 'StartTrial',
  Lead: 'Lead',
  Purchase: 'Purchase'
};

// Google Analytics id
export const gaID = 'UA-79446814-18';

// Google tag manager id
export const gtmID = 'GTM-W85LF4F';

export const SubReasonAboutDate = [
  '23',
  '24',
  '25',
  '11',
  '12',
  '9',
  '26',
  '13'
];
export const OtherReasonAboutDateValues = '7';
export const AnotherOptionReasonAboutDateValues = '0';
export const SubOtherReasonAboutDateValues = '13';
export const OtherReasonAboutServiceValues = '9';

export const LinkDocPlanLight =
  'https://docs.google.com/forms/d/e/1FAIpQLSdHmZHQz2zmW7ki1vXrfrosb9bKg4cYyUrWOljFz0NxaZTYog/viewform';
export const LinkDocPlanNormal =
  'https://docs.google.com/forms/d/e/1FAIpQLScvBGirIB9upBh0hg-Xdw2_hY5Ot1Oc5Kq5XEaRZ3Z8CDJdkg/viewform';
export const LinkDocPlanPremium =
  'https://docs.google.com/forms/d/e/1FAIpQLSe9Az24wcnHUQdX_ru9KRMlauKFAmG1jPffBdpITpN5LQaBvA/viewform';
export const InvitationCode = {
  DEFAULT_CODE: 'BACHELOR_DATE'
};

// Education constant
export const Education = {
  Master: '1',
  Bachelor: '2',
  AssociateDiploma: '3',
  HighSchoolGraduation: '4',
  Other: '99'
};

export const EducationGroup = {
  HighSchool: 1,
  AssociateDiploma: 2,
  LessFamousUni: 3,
  MediumStandardUni: 4,
  FamousUni: 5,
  MoreFamousUni: 6
};

export const BachelorMemberRate = {
  Average: 3
};

// Check email error by English
// TODO: don't need this constant if we have returned error by locale
export const EmailError = {
  InvalidEmail: 'Please enter a valid email address.',
  ExistedEmail: 'Email already exists.'
};

export const CANCEL_DEACTIVATE_ACCOUNT_ERROR = {
  NEED_TO_CANCEL_PARTICIPATION: 'need_to_cancel_participation',
  NEED_TO_PAY_INVOICE: 'need_to_pay_invoice',
  HOSTED_INVOICE_URL: 'hosted_invoice_url',
  NEED_TO_CANCEL_PARTICIPATION_FOR_REMATCH:
    'need_to_cancel_participation_for_rematch',
  HAS_AWAITING_MAIN_MATCH_SAMPLE_DATE_COMPLETED_FORM:
    'has_awaiting_parti_main_match_sample_date_and_completed_form',
  NEED_TO_CANCEL_DATING: 'need_to_cancel_dating',
  NEED_TO_GIVE_FEEDBACK: 'need_to_give_feedback',
  HAS_AWAITING_MAIN_MATCH_BUT_NOT_SAMPLE_DATE_OR_NOT_COMPLETED_FORM:
    'has_awaiting_parti_main_match_but_not_sample_date_or_not_completed_form'
};

export const REGISTRATION_STEPS = {
  StepZero: 0,
  StepOne: 1,
  StepTwo: 2,
  StepThree: 3,
  StepFour: 4,
  StepFive: 5,
  StepSix: 6,
  StepSeven: 7,
  StepEight: 8,
  StepNine: 9,
  StepTen: 10,
  StepEleven: 11,
  StepTwelve: 12,
  StepThirteenth: 13,
  StepFourteenth: 14,
  StepFifteenth: 15,
  StepSixteenth: 16
};

export const IMAGE_MINE_TYPE = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
  'image/jpg',
  'image/heic'
];

export const NumberOnlyRegex = /^\d+$/;

export const HREF_EMAIL_RESERVATION = {
  MALE: 'https://bachelorapp.zendesk.com/hc/ja/sections/4404707426713',
  FEMALE: 'https://bachelorapp.zendesk.com/hc/ja/sections/4404733196185'
};

export const DiscountType = {
  NO_DISCOUNT: 'no_discount',
  YOUNG: 'young'
};

export const UserType = {
  NEW: 'new'
};

export const NEW_C_PLANS_URL = 'https://wi.bachelorapp.net/new-c-plans';

export const ABOUT_CHANGE_PLAN_URL =
  'https://bachelorapp.zendesk.com/hc/ja/articles/4404700078233';
export const ShareTwitterUrl = 'https://twitter.com/intent/tweet?text=';
export const ShareLineUrl = 'https://line.me/R/share?text=';

export const SPECIAL_EVENT = {
  CHRISTMAS: 'christmas'
};

export const DateSlot = {
  LUNCH_TIME: 'lunch_time',
  EVENING_TIME: 'evening_time'
};

export const convertDateText = slot =>
  slot === DateSlot.LUNCH_TIME ? 'お昼' : '夕方';
