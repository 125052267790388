import React from 'react';
import styles from './index.module.css';
import TextRead from '../TextRead';
import TextUnread from '../TextUnread';
import Autolinker from 'autolinker';
import classnames from 'classnames';
//
// Shows the base user's text bubble. Appears to the right of screen.
// -----------------------
const UserTextMessage = ({
  message,
  read,
  onUnreadClick,
  createDate,
  createTime,
  isWhiteMode
}) => {
  const htmlMessage = Autolinker.link(message, {
    stripPrefix: false
  });

  return (
    <span
      className={classnames(styles.userTextMessage, {
        [styles.userTextShowTime]: createTime,
        [styles.userTextShowDate]: createDate
      })}
    >
      {read ? <TextRead /> : <TextUnread onUnreadClick={onUnreadClick} />}
      <div
        className={classnames(styles.chatUserTextBubble, {
          [styles.activeWhiteMode]: isWhiteMode
        })}
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
      />
      {createDate ? (
        <div className={styles.chatUserCreateDate}>{createDate}</div>
      ) : null}
      {createTime ? (
        <div className={styles.chatUserCreateTime}>{createTime}</div>
      ) : null}
    </span>
  );
};

export default UserTextMessage;
