import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* updateTempCancelTrial(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(requestAPI, API.UPDATE_TEMP_CANCEL_TRIAL, API.PUT);

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_TEMP_CANCEL_TRIAL_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.UPDATE_TEMP_CANCEL_TRIAL_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_TEMP_CANCEL_TRIAL_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchUpdateTempCancelTrial() {
  yield takeLatest(
    types.UPDATE_TEMP_CANCEL_TRIAL_REQUESTED,
    updateTempCancelTrial
  );
}
