import * as types from '../actions/types';

const initialState = {
  isError: false,
  error: null,
  stepOnedata: {},
  stepTwodata: {},
  stepThreedata: {},
  stepFourdata: {},
  stepFivedata: {},
  stepSixdata: {},
  stepSevendata: {},
  stepEightdata: {}
};

const firstRegistrationFormReducer = (state = initialState, action) => {
  switch (action.type) {
    //GET REQUEST
    case types.REGISTRATION_FORM_GET_STEP_ONE_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED:
      return {
        ...state,
        stepOnedata: action.data
      };
    case types.REGISTRATION_FORM_GET_STEP_ONE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_GET_STEP_TWO_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED:
      return {
        ...state,
        stepTwodata: action.data
      };
    case types.REGISTRATION_FORM_GET_STEP_TWO_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.REGISTRATION_FORM_GET_STEP_THREE_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED:
      return {
        ...state,
        stepThreedata: action.data
      };
    case types.REGISTRATION_FORM_GET_STEP_THREE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED:
      return {
        ...state,
        stepFourdata: action.data
      };
    case types.REGISTRATION_FORM_GET_STEP_FOUR_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED:
      return {
        ...state,
        stepFivedata: action.data
      };
    case types.REGISTRATION_FORM_GET_STEP_FIVE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_GET_STEP_SIX_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED:
      return {
        ...state,
        stepSixdata: action.data
      };
    case types.REGISTRATION_FORM_GET_STEP_SIX_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED:
      return {
        ...state,
        stepSevendata: action.data
      };
    case types.REGISTRATION_FORM_GET_STEP_SEVEN_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED:
      return {
        ...state,
        stepEightdata: action.data
      };
    case types.REGISTRATION_FORM_GET_STEP_EIGHT_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    //POST REQUEST

    case types.REGISTRATION_FORM_POST_STEP_ONE_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED:
      return {
        ...state,
        stepOnedata: action.data
      };
    case types.REGISTRATION_FORM_POST_STEP_ONE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_POST_STEP_TWO_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED:
      return {
        ...state,
        stepTwodata: action.data
      };
    case types.REGISTRATION_FORM_POST_STEP_TWO_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_POST_STEP_THREE_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED:
      return {
        ...state,
        stepThreedata: action.data
      };
    case types.REGISTRATION_FORM_POST_STEP_THREE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };

    case types.REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED:
      return {
        ...state,
        stepFourdata: action.data
      };
    case types.REGISTRATION_FORM_POST_STEP_FOUR_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED:
      return {
        ...state,
        stepFivedata: action.data
      };
    case types.REGISTRATION_FORM_POST_STEP_FIVE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_POST_STEP_SIX_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED:
      return {
        ...state,
        stepSixdata: action.data
      };
    case types.REGISTRATION_FORM_POST_STEP_SIX_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED:
      return {
        ...state,
        stepSevendata: action.data
      };
    case types.REGISTRATION_FORM_POST_STEP_SEVEN_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED:
      return {
        ...state
      };
    case types.REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED:
      return {
        ...state,
        stepEightdata: action.data
      };
    case types.REGISTRATION_FORM_POST_STEP_EIGHT_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default firstRegistrationFormReducer;
