import React from 'react';
import NavBarMain from '../NavBar';
import styles from './index.module.css';

import ChatRoom from './ChatRoom';
import ChatTextInput from './ChatTextInput';
import ChatLinkCustom from './ChatLinkCustom';
import { renderLeftContent } from '../../utils';
import classNames from 'classnames';
import { ReactComponent as ArrowRightIcon } from '@assets/ArrowRight.svg';
import moment from 'moment';
const ChatFeature = ({
  chatRoomIsReady,
  partnerProfileImage,
  partnerData,
  chatRoomMessages,
  bachelorChatManager,
  meetingTimePopup,
  wantCancelPopup,
  transferTicketPopup,
  linkTextChat,
  linkTextChat1,
  linkTextChat2,
  linkTextChat3,
  fetchMoreMessages,
  shouldScrollToLatestMessage,
  textAgeS,
  saveChatHistory,
  onUserAvatarClick,
  onBackButtonClick,
  chatfeatureHeaderContent,
  chatfeatureHeaderLine,
  isWhiteMode,
  dateTimeText,
  isLunchTime,
  handelClickGoToDetailPartner
}) => {
  const { profileImage, job, age } = partnerData || {};
  let cafeInfo = {};
  if (chatRoomMessages.length > 0) {
    cafeInfo = chatRoomMessages[0].data;
  }
  const dtLength = dateTimeText.length;
  const datingTime = dateTimeText.substring(dtLength - 9, dtLength - 1);
  let newDatingTime = moment(datingTime, 'HH:mm:ss', true).isValid()
    ? moment(datingTime, 'HH:mm').format('HH:mm')
    : null;
  if (newDatingTime) {
    dateTimeText = dateTimeText.replace(datingTime, newDatingTime);
  }

  return (
    <>
      <NavBarMain
        customClassName={styles.navBar}
        isWhiteMode={isWhiteMode}
        title={job && `${age}${textAgeS}${job}`}
        userAvatar={partnerProfileImage ? partnerProfileImage : ''}
        shouldBlurIcon={false}
        onUserAvatarClick={onUserAvatarClick}
        dateTimeText={dateTimeText}
        isLunchTime={isLunchTime}
        leftContent={[renderLeftContent(true, isWhiteMode, onBackButtonClick)]}
      />
      <div
        className={classNames(styles.wrapper, {
          [styles.wrapperWhiteMode]: isWhiteMode
        })}
      >
        <div
          className={styles.boxHeaderContainer}
          onClick={() => handelClickGoToDetailPartner()}
        >
          <div className={styles.boxHeaderContent}>
            <img
              alt=""
              src={partnerProfileImage ? partnerProfileImage : ''}
              className={classNames({
                [styles.navIconImgChat]: true
              })}
            />
            <div>
              <div className={styles.headerContent}>
                {age}歳, {job ? job.displayName : null}
              </div>
              <div className={styles.headerContent}>
                待ち合わせ:{cafeInfo ? cafeInfo.cafeName : null}
              </div>
            </div>
          </div>
          <div>
            <ArrowRightIcon />
          </div>
        </div>

        <div className={styles.textHorizontalLine}>
          チャットは当日22時まで利用可能です。
        </div>
        <ChatRoom
          chatRoomIsReady={chatRoomIsReady}
          messages={chatRoomMessages}
          bachelorChatManager={bachelorChatManager}
          fetchMoreMessages={fetchMoreMessages}
          shouldScrollToLatestMessage={shouldScrollToLatestMessage}
          partnerData={partnerData}
        />

        <div className={styles.containerFirstBtn}>
          <ChatLinkCustom
            isWhiteMode={isWhiteMode}
            text={linkTextChat1}
            onChatLinkClick={transferTicketPopup}
          />
        </div>

        <div className={styles.horizontalTextLink}>
          <ChatLinkCustom
            isWhiteMode={isWhiteMode}
            customStyle={styles.bottomTextLink1}
            className={styles.bottomTextLink1}
            text={linkTextChat2}
            leftButton
            onChatLinkClick={wantCancelPopup}
          />
          <ChatLinkCustom
            isWhiteMode={isWhiteMode}
            customStyle={styles.bottomTextLink2}
            className={styles.bottomTextLink2}
            text={linkTextChat3}
            rightButton
            onChatLinkClick={meetingTimePopup}
          />
        </div>

        <ChatTextInput
          isWhiteMode={isWhiteMode}
          chatRoomIsReady={chatRoomIsReady}
          bachelorChatManager={bachelorChatManager}
          saveChatHistory={saveChatHistory}
        />
      </div>
    </>
  );
};

export default ChatFeature;
