import React from 'react';
import { pathOr } from 'ramda';
import Popup from '../../components/Popup';
import { compose } from 'recompose';
import { connect } from 'react-redux';

class ErrorPopup extends React.PureComponent {
  handleCancel = () => {
    const { hideError } = this.props;
    if (hideError) {
      hideError();
    }
  };

  handleCancelGlobal = () => {
    const { resetErrorPopup, globalNextUrl } = this.props;
    if (resetErrorPopup) {
      resetErrorPopup();
    }
    if (globalNextUrl) {
      window.location.replace(globalNextUrl);
    }
  };

  render() {
    const {
      message,
      error,
      globalErrorMessage,
      globalErrorTitle,
      globalNextUrl
    } = this.props;

    if (globalErrorTitle) {
      if (globalErrorTitle[0] === '一時休止中です') {
        return (
          <Popup
            visible={true}
            title={globalErrorTitle}
            messages={[
              '再開時にEメールにてお知らせいたします。お楽しみにお待ちください。'
            ]}
            messageClassName={'align-left'}
            notShowCloseButton={true}
            okButtonTitle={'閉じる'}
            onOkClicked={this.handleCancelGlobal}
          />
        );
      }
      if (globalNextUrl) {
        return (
          <Popup
            visible={true}
            title={globalErrorTitle}
            messages={globalErrorMessage}
            onCancelClicked={this.handleCancelGlobal}
            okButtonTitle={'お支払い確認ページへ'}
            onOkClicked={this.handleCancelGlobal}
          />
        );
      } else {
        return (
          <Popup
            visible={true}
            title={globalErrorTitle}
            messages={globalErrorMessage}
            onCancelClicked={this.handleCancelGlobal}
          />
        );
      }
    }

    const errorCode = pathOr(-1, ['response', 'status'], error);

    // Don't show popup unauthorzied
    if (errorCode === 401) {
      return null;
    }

    if (!message) {
      return null;
    }

    return (
      <Popup
        visible={true}
        title={message}
        onCancelClicked={this.handleCancel}
        MessagesComponent={<></>}
        ButtonComponent={<></>}
      />
    );
  }
}

ErrorPopup.propTypes = {};

ErrorPopup.defaultProps = {};

const mapStateToProps = state => {
  let errorMessage = '';
  const globalErrorMessage = state.globalErrorPopup.message || [];
  const globalErrorTitle = state.globalErrorPopup.title || '';
  const globalNextUrl = state.globalErrorPopup.nextUrl || undefined;

  errorMessage = state.auth.isError
    ? pathOr('', ['auth', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.auth.error
    };
  }

  errorMessage = state.homescreen.isError
    ? pathOr('', ['homescreen', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.homescreen.error
    };
  }

  errorMessage = state.match.isError
    ? pathOr('', ['match', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.match.error
    };
  }

  errorMessage = state.popups.isError
    ? pathOr('', ['popups', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.popups.error
    };
  }

  errorMessage = state.userProfile.isError
    ? pathOr('', ['userProfile', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.userProfile.error
    };
  }

  errorMessage = state.chatRoom.isError
    ? pathOr('', ['chatRoom', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.chatRoom.error
    };
  }

  errorMessage = state.cafe.isError
    ? pathOr('', ['cafe', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.cafe.error
    };
  }

  errorMessage = state.chatMessages.isError
    ? pathOr('', ['chatMessages', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.chatMessages.error
    };
  }

  // Show popup error for coupons
  errorMessage = state.coupons.isError
    ? pathOr('', ['coupons', 'error'], state)
    : '';

  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.coupons.error
    };
  }

  return {
    message: '',
    error: null,
    globalErrorMessage,
    globalErrorTitle,
    globalNextUrl
  };
};

export default compose(connect(mapStateToProps, {}))(ErrorPopup);
