import { all } from 'redux-saga/effects';
import {
  watchSMSSendCode,
  watchSmsVerificationCode,
  watchLoginFacebook,
  watchGetFacebook,
  watchLogoutFacebook,
  watchReActivationReApprove
} from './authSaga';
import { watchHomeScreen } from './homescreenSaga';
import {
  watchGetMatchData,
  watchGetMatchProfileDetail,
  watchCancelDateByPartner,
  watchParticipateRematch,
  watchCancelForRematch,
  watchAddRequestTimeChange,
  watchUpdateRequestTimeChange,
  watchGetCurrentServerTime
} from './matchSaga';
import {
  watchCancelParticipate,
  watchCancelSampleParticipant,
  watchGetAwaitingParticipantDatingDays,
  watchGetOpenDates,
  watchGetOpenDateStatus,
  watchGetRecommendations,
  watchRequestParticipate,
  watchSetParticipation
} from './participationSaga';
import { watchGetPopupsData, watchDeletePopupData } from './popupsSaga';
import {
  watchGetPlanSubcription,
  watchGetPlanSubscription,
  watchGetUserProfile,
  watchGetPreferencesInfo,
  watchUpdatePreferences,
  watchUpdateProfileInfo
} from './userProfileSaga';
import { watchUseCoupon } from './useCouponSaga';
import { watchCancelDate } from './cancelDateSaga';
import { watchUpdateEmail } from './updateEmailSaga';
import { watchGetUserGlobalInfo } from './userGlobalInforSaga';
import { watchChatRoom, watchGetTwilioToken } from './chatRoomSaga';
import { watchChatHistory } from './chatHistorySaga';
import { watchGetCafe } from './cafeSaga';
import { watchGetChatMessages } from './chatMessagesSaga';
import {
  watchDeactiveProfile,
  watchValidateDeactivateProfile
} from './deactiveProfileSaga';
import {
  watchCancelChangeCostPlan,
  watchChangeCostPlan
} from './changePlanSaga';
import { watchCancelProfile } from './cancelProfileSaga';
import {
  watchSaveUserFeedback,
  watchGetUserFeedbackDetails,
  watchCheckSendFeedback
} from './feedbackSaga';
import { watchGetInviteFriendsData } from './inviteFriendsSaga';
import { watchGetMatchProfileInfoData } from './getMatchProfileInfoSaga';
import { watchGetEvaluationInfoData } from './getEvaluationInfoSaga';
import { watchGetEvaluationListData } from './getEvaluationListSaga';
import {
  watchGetCouponsData,
  watchExchangeCouponsData,
  watchApplyCoupon,
  watchRetrieveUserCoupon,
  watchPurchaseCoupon,
  watchGetCouponPackages
} from './couponsSaga';
import { watchRematch } from './rematchSaga';
import { watchUseImage } from './userImageSagas';
import { watchValidateSelecting } from './validateSelectingSaga';
import {
  watchGetStepOne,
  watchGetStepTwo,
  watchGetStepThree,
  watchGetStepFour,
  watchGetStepFive,
  watchGetStepSix,
  watchPostStepOne,
  watchPostStepTwo,
  watchPostStepThree,
  watchPostStepFour,
  watchPostStepFive,
  watchPostStepSix,
  watchPostStepSeven,
  watchGetStepSeven,
  watchGetStepEight,
  watchPostStepEight
} from './registationFormSaga';

import {
  watchGetStepOne as watchGetStepOne2nd,
  watchGetStepTwo as watchGetStepTwo2nd,
  watchGetStepThree as watchGetStepThree2nd,
  watchGetStepFour as watchGetStepFour2nd,
  watchGetStepFive as watchGetStepFive2nd,
  watchGetStepSix as watchGetStepSix2nd,
  watchPostStepOne as watchPostStepOne2nd,
  watchPostStepTwo as watchPostStepTwo2nd,
  watchPostStepThree as watchPostStepThree2nd,
  watchPostStepFour as watchPostStepFour2nd,
  watchPostStepFive as watchPostStepFive2nd,
  watchPostStepSix as watchPostStepSix2nd,
  watchPostStepSeven as watchPostStepSeven2nd,
  watchGetStepSeven as watchGetStepSeven2nd,
  watchGetStepEight as watchGetStepEight2nd,
  watchPostStepEight as watchPostStepEight2nd,
  watchGetCondition,
  watchUpdatePrefectureAndArea
} from './2ndRegistrationFormSaga';
import {
  watchCreateCard,
  watchGetCards,
  watchSetDefaultCard
} from './cardsSaga';
import { watchGetMyPageInfoSaga } from './myPageInfoSaga';
import { watchGetDatingDayAbleToChat } from './datingDayAbleToChatSaga';
import {
  watchGetPlan,
  watchChangeUserPlan,
  watchValidateBeforeChangeUserPlan
} from './userPlanSaga';
import { watchAccessChatRoom } from './chatRoomSaga';
import {
  watchChangeDatingStatus,
  watchChangeMultipleDatingStatus
} from './datingStatusSaga';
import { watchUpdateTempCancelTrial } from './tempCancelTrialSaga';
import { watchGetUserInfoFacebookPixel } from './userInfoFacebookPixelSaga';
import { watchSendNoticeException } from './exceptionSaga';
import { watchSubscriptionPausedRequest } from './subscriptionPausedSaga';
import { watchUpdateUserMotivatedDating } from './userMotivatedDatingSaga';
export default function* rootSaga() {
  yield all([
    watchSMSSendCode(),
    watchSmsVerificationCode(),
    watchLoginFacebook(),
    watchLogoutFacebook(),
    watchGetFacebook(),
    watchHomeScreen(),
    watchGetMatchData(),
    watchUpdateRequestTimeChange(),
    watchAddRequestTimeChange(),
    watchSetParticipation(),
    watchGetPopupsData(),
    watchDeletePopupData(),
    watchUseCoupon(),
    watchCancelDate(),
    watchUpdateEmail(),
    watchGetUserGlobalInfo(),
    watchCancelDateByPartner(),
    watchParticipateRematch(),
    watchCancelForRematch(),
    watchChatRoom(),
    watchGetTwilioToken(),
    watchChatHistory(),
    watchGetCafe(),
    watchGetChatMessages(),
    watchCancelChangeCostPlan(),
    watchChangeCostPlan(),
    watchDeactiveProfile(),
    watchValidateDeactivateProfile(),
    watchCancelProfile(),
    watchSaveUserFeedback(),
    watchGetUserFeedbackDetails(),
    watchGetInviteFriendsData(),
    watchGetMatchProfileInfoData(),
    watchGetCouponsData(),
    watchExchangeCouponsData(),
    watchGetCouponPackages(),
    watchRematch(),
    watchGetEvaluationInfoData(),
    watchGetEvaluationListData(),
    watchCheckSendFeedback(),
    watchGetCurrentServerTime(),

    //PARTICIPATION
    watchGetOpenDates(),
    watchRequestParticipate(),
    watchCancelParticipate(),
    watchCancelSampleParticipant(),
    watchGetRecommendations(),
    watchGetAwaitingParticipantDatingDays(),
    watchGetOpenDateStatus(),

    //COUPONS
    watchApplyCoupon(),
    watchRetrieveUserCoupon(),
    watchPurchaseCoupon(),

    //registration form
    watchGetStepOne(),
    watchGetStepTwo(),
    watchGetStepThree(),
    watchGetStepFour(),
    watchGetStepFive(),
    watchGetStepSix(),
    watchGetStepSeven(),
    watchGetStepEight(),
    watchPostStepOne(),
    watchPostStepTwo(),
    watchPostStepThree(),
    watchPostStepFour(),
    watchPostStepFive(),
    watchPostStepSix(),
    watchPostStepSeven(),
    watchPostStepEight(),

    //2nd registration form
    watchGetStepOne2nd(),
    watchGetStepTwo2nd(),
    watchGetStepThree2nd(),
    watchGetStepFour2nd(),
    watchGetStepFive2nd(),
    watchGetStepSix2nd(),
    watchGetStepSeven2nd(),
    watchPostStepOne2nd(),
    watchPostStepTwo2nd(),
    watchPostStepThree2nd(),
    watchPostStepFour2nd(),
    watchPostStepFive2nd(),
    watchPostStepSix2nd(),
    watchPostStepSeven2nd(),
    watchGetStepEight2nd(),
    watchPostStepEight2nd(),
    watchGetCondition(),
    watchUpdatePrefectureAndArea(),

    watchReActivationReApprove(),
    watchGetMatchProfileDetail(),
    //User profile & preferences
    watchGetPlanSubscription(),
    watchGetPreferencesInfo(),
    watchUpdatePreferences(),
    watchGetUserProfile(),
    watchUpdateProfileInfo(),
    watchGetMyPageInfoSaga(),
    watchUseImage(),
    //Cards
    watchGetCards(),
    watchCreateCard(),
    watchSetDefaultCard(),
    //Dating Day able to chat
    watchGetDatingDayAbleToChat(),
    //Plans
    watchGetPlan(),
    watchChangeUserPlan(),
    watchValidateBeforeChangeUserPlan(),

    //Access chat room
    watchAccessChatRoom(),

    watchChangeDatingStatus(),
    watchChangeMultipleDatingStatus(),
    watchUpdateTempCancelTrial(),

    // get user info for fb pixel
    watchGetUserInfoFacebookPixel(),
    // Validate Selecting date
    watchValidateSelecting(),
    // send notice exception
    watchSendNoticeException(),

    //send request subscription paused
    watchSubscriptionPausedRequest(),

    //user motivated dating
    watchUpdateUserMotivatedDating()
  ]);
}
