import React, { Component } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
import classNames from 'classnames';
import bachelorUser from '../../../assets/bachelorUser.png';

class CafeInformationMessage extends Component {
  render() {
    const { t, cafeInfo, isWhiteMode, partnerData } = this.props;
    const {
      datingDateTime,
      cafeName,
      stationName,
      cafePhone,
      cafeLink
    } = cafeInfo;
    const { job, age } = partnerData || {};
    return (
      <span className={styles.partnerTextMessage}>
        <img alt="" src={bachelorUser} className={styles.chatUserIcon} />
        <div
          className={classNames(styles.chatPartnerTextBubble, {
            [styles.textBlack]: isWhiteMode
          })}
        >
          <div>{datingDateTime}</div>
          <div>
            {t('cafe-info-message-line-2')}
            <a
              href={cafeLink}
              target={'_blank'}
              className={isWhiteMode ? styles.textBlack : ''}
            >
              <u>
                {cafeName} ({stationName})
              </u>
            </a>
          </div>
          <div>
            {t('cafe-info-message-line-3')}
            <a
              href={'tel:' + cafePhone}
              className={isWhiteMode ? styles.textBlack : ''}
            >
              <u>{cafePhone}</u>
            </a>
          </div>
          <div>
            お相手: {age}歳, {job ? job.displayName : null}
          </div>
          <br />
          <div>{t('cafe-info-message-line-1-1')}</div>
          <div>{t('cafe-info-message-line-1-2')}</div>
          {/*<br />*/}
          {/*{t('cafe-info-message-line-4')}*/}
        </div>
      </span>
    );
  }
}

export default compose(withNamespaces('chat', { wait: true }))(
  CafeInformationMessage
);
