import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getStepOne(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/8',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_ONE_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_ONE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_GET_STEP_ONE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* postStepOne(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/8/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_ONE_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_ONE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_POST_STEP_ONE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* getStepTwo(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/9',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_TWO_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_TWO_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_GET_STEP_TWO_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}
function* postStepTwo(action) {
  let response = null;
  let stepUpdate = `/9/store`;
  store.dispatch(showLoading());
  if (action.params.updatePartnerPreference) {
    stepUpdate = `/1/store`;
  }
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + stepUpdate,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_TWO_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_TWO_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_POST_STEP_TWO_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* getStepThree(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/10',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_THREE_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_THREE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_GET_STEP_THREE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* postStepThree(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/10/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_THREE_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_THREE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_POST_STEP_THREE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* getStepFour(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/11',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_FOUR_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_FOUR_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_GET_STEP_FOUR_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* postStepFour(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/11/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_FOUR_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_FOUR_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_POST_STEP_FOUR_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* getStepFive(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/12',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_FIVE_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_FIVE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_GET_STEP_FIVE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}
function* postStepFive(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/12/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_FIVE_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_FIVE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_POST_STEP_FIVE_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* getStepSix(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/13',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_SIX_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_SIX_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_GET_STEP_SIX_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}
function* postStepSix(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/13/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_SIX_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_SIX_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_POST_STEP_SIX_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* getStepSeven(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/14',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}
function* postStepSeven(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/14/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* getStepEight(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + '/15',
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.REGISTRATION_FORM_GET_STEP_EIGHT_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getConditionChangePrefecture(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_CONDITION_CHANGE_PREFECTURE_AND_AREA,
      API.GET,
      action.params,
      false
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CONDITION_CHANGE_PREFECTURE_AND_AREA_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.CONDITION_CHANGE_PREFECTURE_AND_AREA_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.CONDITION_CHANGE_PREFECTURE_AND_AREA_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* updatePrefectureAndArea(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_CHANGE_PREFECTURE_AND_AREA,
      API.POST,
      action.params,
      false
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_PREFECTURE_AND_AREA_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.UPDATE_PREFECTURE_AND_AREA_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_PREFECTURE_AND_AREA_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

function* postStepEight(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_REGISTRATION_FORM + `/15/store`,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_SUCCEDDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({
      type: types.SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_FAILED,
      error
    });
  }
  store.dispatch(hideLoading());
}

//get registration form
export function* watchGetStepOne() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_GET_STEP_ONE_REQUESTED,
    getStepOne
  );
}
export function* watchGetStepTwo() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_GET_STEP_TWO_REQUESTED,
    getStepTwo
  );
}
export function* watchGetStepThree() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_GET_STEP_THREE_REQUESTED,
    getStepThree
  );
}
export function* watchGetStepFour() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED,
    getStepFour
  );
}
export function* watchGetStepFive() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED,
    getStepFive
  );
}
export function* watchGetStepSix() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_GET_STEP_SIX_REQUESTED,
    getStepSix
  );
}
export function* watchGetStepSeven() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED,
    // types.REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED,
    getStepSeven
  );
}
export function* watchGetStepEight() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED,
    getStepEight
  );
}

export function* watchGetCondition() {
  yield takeLatest(
    types.CONDITION_CHANGE_PREFECTURE_AND_AREA_REQUESTED,
    getConditionChangePrefecture
  );
}

export function* watchUpdatePrefectureAndArea() {
  yield takeLatest(
    types.UPDATE_PREFECTURE_AND_AREA_REQUESTED,
    updatePrefectureAndArea
  );
}
// post registration form
export function* watchPostStepOne() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_POST_STEP_ONE_REQUESTED,
    postStepOne
  );
}

export function* watchPostStepTwo() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_POST_STEP_TWO_REQUESTED,
    postStepTwo
  );
}

export function* watchPostStepThree() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_POST_STEP_THREE_REQUESTED,
    postStepThree
  );
}

export function* watchPostStepFour() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED,
    postStepFour
  );
}

export function* watchPostStepFive() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED,
    postStepFive
  );
}
export function* watchPostStepSix() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_POST_STEP_SIX_REQUESTED,
    postStepSix
  );
}

export function* watchPostStepSeven() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED,
    postStepSeven
  );
}

export function* watchPostStepEight() {
  yield takeLatest(
    types.SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED,
    postStepEight
  );
}
