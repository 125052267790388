import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* useCoupon(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.USE_COUPON, API.POST, action.params);

    if (
      response.status === ResponseStatus.SUCCESS ||
      response.status === ResponseStatus.FEEDBACK_NOT_GIVEN
    ) {
      yield put({ type: types.USE_COUPON_SUCCEEDED, data: response.data });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({ type: types.USE_COUPON_FAILED, error: response });
    } else {
      yield put({ type: types.USE_COUPON_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.USE_COUPON_FAILED, error });
  }
}

export function* watchUseCoupon() {
  // yield takeLatest(types.USE_COUPON_REQUESTED, useCoupon);
}
