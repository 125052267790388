import React from 'react';
import styles from './index.module.css';
import classNames from 'classnames';
//
// Shows the chat links that appear in gold below messages.
// Hiển thị các liên kết trò chuyện xuất hiện bằng vàng bên dưới tin nhắn.
// -----------------------
const ChatLink = ({ text, onChatLinkClick, isWhiteMode }) => (
  <span className={styles.chatLink} onClick={onChatLinkClick}>
    <div
      className={classNames(styles.chatLinkText, {
        [styles.textBlack]: isWhiteMode
      })}
    >
      {text}
    </div>
  </span>
);

export default ChatLink;
