import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { path } from 'ramda';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import GTagManager from 'react-gtm-module';
import asyncComponent from './hoc/asyncComponent';
import { LoadingSpinner } from './containers/Loading';
import { ErrorPopup } from './containers/Error';
import ChatFeature from './containers/ChatFeature';
import WebTemplateMobileSimulator from '@components/WebTemplateMobileSimulator';
import BottomTabBar from '@components/BottomTabBar';
import Popup from '@components/Popup';
import MessageCustom from '@components/Popup/MessageCustom';
import {
  getPopups,
  getMatchDetail,
  userProfile,
  getTwilioToken,
  requestUserGlobalInfo,
  requestUserInfoFacebookPixel,
  hideLoading,
  requestSendExceptionNotice
} from './redux/actions';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { PopupPortal } from '@portals/';
import {
  GlobalPopup as GlobalPopupConstants,
  UserGender,
  FacebookPixelEventName,
  UserStatus,
  gtmID,
  LpQueryStr
} from '@constants/constant';
import ServerPopupContainer from '@containers/ServerPopup';
import DrawerMenu from '@components/DrawerMenu';
import Cookies from 'universal-cookie';
import withTracker from './hoc/withTracker';
import { fbInit, fbPixelTrackEvent } from './helper/FbPixelHelper';
import { matomoPageView } from './helper/MatomoHelper';
import moment from 'moment';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { store } from './redux/store';
import { Colors } from './constants/constant';

const cookies = new Cookies();

const {
  CHANGE_COST_PLAN_DOWNGRADE,
  CHANGE_COST_PLAN_UPGRADE,
  CHANGE_COST_PLAN,
  COMPLETE_UPDATE_PROFILE
} = GlobalPopupConstants;

// Min screen width used to determine whether to render mobile template
const DEVICE_WIDTH = 1224;

const AsyncLoginPage = asyncComponent(() => {
  return import('./containers/LoginPage/Loadable');
});

const AsyncSMSLoginPage = asyncComponent(() => {
  return import('./containers/SMSLogin/Loadable');
});
const AsyncSMSVerificationPage = asyncComponent(() => {
  return import('./containers/SMSLogin/verification');
});
const AsyncRegistrationFormPage = asyncComponent(() => {
  return import('./containers/RegistrationForm/FirstPage');
});
const AsyncStepOnePage = asyncComponent(() => {
  return import('./containers/RegistrationForm/StepOne');
});
const Async2ndStepOnePage = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/StepOne/Loadable');
});

const AsyncStepTwoPage = asyncComponent(() => {
  return import('./containers/RegistrationForm/StepTwo');
});
const Async2ndStepTwoPage = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/StepTwo/Loadable');
});
const AsyncStepThreePage = asyncComponent(() => {
  return import('./containers/RegistrationForm/StepThree');
});
const Async2ndStepThreePage = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/StepThree/Loadable');
});
const AsyncStepFourPage = asyncComponent(() => {
  return import('./containers/RegistrationForm/StepFour');
});
const Async2ndStepFourPage = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/StepFour/Loadable');
});
const AsyncStepFivePage = asyncComponent(() => {
  return import('./containers/RegistrationForm/StepFive');
});
const Async2ndStepFivePage = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/StepFive/Loadable');
});
const AsyncStepSixPage = asyncComponent(() => {
  return import('./containers/RegistrationForm/StepSix');
});
const AsyncStepSevenPage = asyncComponent(() => {
  return import('./containers/RegistrationForm/StepSeven');
});

const Async2ndStepSixPage = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/StepSix/Loadable');
});
const Async2ndStepSevenPage = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/StepSeven/Loadable');
});
const Async2ndStepEightPage = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/StepEight/Loadable');
});
const AsyncLastStepAddCard = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/LastStepAddCard/Loadable');
});
const AsyncBachelorPage = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/BachelorPage/Loadable');
});

const AsyncSlideShow = asyncComponent(() => {
  return import('./containers/2ndRegistrationForm/SlideShowPage/Loadable');
});

const AsyncServerRepair = asyncComponent(() => {
  return import('./containers/ServerRepair/Loadable');
});

const AsyncAboutDeactive = asyncComponent(() => {
  return import('./containers/AboutDeactive/Loadable');
});

const AsyncAboutDeactiveApp = asyncComponent(() => {
  return import('./containers/AboutDeactiveApplication/Loadable');
});

const AsyncCurrentUserProfile = asyncComponent(() => {
  return import('./containers/CurrentUserProfile/Loadable');
});

const AsyncLogoutPage = asyncComponent(() => {
  return import('./containers/LogoutPage/Loadable');
});

const AsyncMatchProfile = asyncComponent(() => {
  return import('./containers/MatchProfile/Loadable');
});
const AsyncHomeScreen = asyncComponent(() => {
  return import('./containers/HomeScreen/Loadable');
});
const AsyncParticipation = asyncComponent(() => {
  return import('./containers/Participation/Loadable');
});
const AsyncDatingConfirmation = asyncComponent(() => {
  return import('./containers/DatingConfirmation/Loadable');
});

const AsyncTicketPurchase = asyncComponent(() => {
  return import('./containers/TicketPurchase/Loadable');
});
const AsyncDateTicket = asyncComponent(() => {
  return import('./containers/DateTicket/Loadable');
});
const AsyncPartnerDetail = asyncComponent(() => {
  return import('./containers/PartnerDetail/Loadable');
});
const AsyncProfileDetail = asyncComponent(() => {
  return import('./containers/ProfileDetail/Loadable');
});
const AsyncCoupon = asyncComponent(() => {
  return import('./containers/Coupon/Loadable');
});
const AsyncCouponExchange = asyncComponent(() => {
  return import('./containers/CouponExchange/Loadable');
});
const AsyncEmailRegistration = asyncComponent(() => {
  return import('./containers/EmailRegistration/Loadable');
});
const AsyncEmailReservation = asyncComponent(() => {
  return import('./containers/EmailReservation/Loadable');
});
const AsyncCancelDateApplication = asyncComponent(() => {
  return import('@containers/CancelDateApplication/Loadable');
});
const AsyncRematchingApplication = asyncComponent(() => {
  return import('@containers/RematchingApplication/Loadable');
});
const AsyncHelp = asyncComponent(() => {
  return import('@containers/Help/Loadable');
});
const AsyncContact = asyncComponent(() => {
  return import('@containers/Contact/Loadable');
});
const AsyncAboutCancellation = asyncComponent(() => {
  return import('@components/AboutCancellation/Loadable');
});
const AsyncCancellationApplication = asyncComponent(() => {
  return import('@containers/CancellationApplication/Loadable');
});
const AsyncLightSuggestion = asyncComponent(() => {
  return import('@containers/LightSuggestion/Loadable');
});
const AsyncFeedback = asyncComponent(() => {
  return import('@containers/Feedback/Loadable');
});
const AsyncDowngradeSuggestion = asyncComponent(() => {
  return import('@containers/DowngradeSuggestion/Loadable');
});
const AsyncInviteFriends = asyncComponent(() => {
  return import('@containers/InviteFriends/Loadable');
});
const AsyncMatchProfileInfo = asyncComponent(() => {
  return import('@containers/MatchProfileInfo/Loadable');
});
const AsyncTrialExtensionSuggestion = asyncComponent(() => {
  return import('@containers/TrialExtensionSuggestion/Loadable');
});
const AsyncEvaluationInfo = asyncComponent(() => {
  return import('@containers/EvaluationInfo/Loadable');
});
const AsyncEvaluationList = asyncComponent(() => {
  return import('@containers/EvaluationList/Loadable');
});
const AsyncParticipatedDate = asyncComponent(() => {
  return import('@containers/ParticipatedDate/Loadable');
});
const AsyncTrialResumption = asyncComponent(() => {
  return import('@containers/TrialResumption/Loadable');
});
const AsyncPaidResumption = asyncComponent(() => {
  return import('@containers/PaidResumption/Loadable');
});
const AsyncResumptionPackage = asyncComponent(() => {
  return import('@containers/ResumptionPackage/Loadable');
});

const AsyncResumptionApplication = asyncComponent(() => {
  return import('@containers/ResumptionApplication/Loadable');
});

const AsyncEmailAddress = asyncComponent(() => {
  return import('@containers/MailAddress/Loadable');
});

const AsyncAddUserCard = asyncComponent(() => {
  return import('@containers/AddUserCard/Loadable');
});
const AsyncProfileDetailPartner = asyncComponent(() => {
  return import('@containers/ProfileDetailPartner/Loadable');
});
const AsyncSubscriptionPaused = asyncComponent(() => {
  return import('@containers/SubscriptionPaused/Loadable');
});
const AsyncSubscriptionPausedRequest = asyncComponent(() => {
  return import('@containers/SubscriptionPausedRequest/Loadable');
});

class App extends Component {
  state = {
    isShowSubPopup: 0,
    titleSubPopup: '',
    messageSubPopup: [],
    // isFbPixelInit: false, // check whether facebook pixel is initialized
    userProfileData: null,
    isCount: false
  };

  checkRedirectToLogin() {
    const { loggedIn } = this.props;
    // define page that does not need user's session
    const notRedirectPages = ['/login', '/sms-login', '/match-info'];
    const parsed = queryString.parse(window.location.search);
    if (parsed.white && parsed.white === '1') {
      localStorage.setItem('isWhiteMode', 'true');
    }
    if (!loggedIn && !notRedirectPages.includes(window.location.pathname)) {
      localStorage.setItem('redirect_to', window.location.pathname);
      this.props.history.push('/login');
    }
    const isWhiteMode = JSON.parse(localStorage.getItem('isWhiteMode'));
    document.body.style.backgroundColor = isWhiteMode
      ? Colors.WHITE
      : Colors.BLACK;
  }

  componentDidMount() {
    this.checkRedirectToLogin();
    // Fetch all server popups when App mounts
    const { loggedIn, userData, userProfileData, userInfoFbPixel } = this.props;
    // Matomo pageview
    matomoPageView(userData || null, true);
    this.runOnUrlUpdatedOrLoad(
      userData,
      userProfileData,
      userInfoFbPixel,
      loggedIn
    );

    if (loggedIn) {
      //Uncomment when go live
      this.requestUserProfile();

      this.ensureAnalyticsCookie();
      this.props.requestUserInfoFacebookPixel(() => {
        this.props.requestUserGlobalInfo();
      });
    }
  }

  handleBeforeUnloadEvent = () => {
    if (this.props.isLoading && !this.state.isCount) {
      this.setState(
        {
          isCount: true
        },
        () => {
          clearInterval(this.timer);
          let time = 0;
          this.timer = setInterval(() => {
            time = time + 1;
            if (time === window.__env__.TIME_LOADING) {
              let errMsg = 'Loading issue happened';
              const userId = this.props.userData
                ? this.props.userData.id
                : null;
              errMsg = userId ? `${errMsg} with user id = ${userId}` : errMsg;
              store.dispatch(
                requestSendExceptionNotice(
                  {
                    error: {
                      message: errMsg + ` at ${window.location.pathname}`
                    }
                  },
                  () => {
                    clearInterval(this.timer);
                  }
                )
              );
            }
          }, 1000);
        }
      );
    }
    if (!this.props.isLoading && this.state.isCount) {
      this.setState(
        {
          isCount: false
        },
        () => {
          clearInterval(this.timer);
        }
      );
    }
  };

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
    this.setState(
      {
        isCount: false
      },
      () => {
        clearInterval(this.timer);
      }
    );
  }

  componentDidUpdate(prevProps) {
    this.handleBeforeUnloadEvent();
    const {
      loggedIn,
      userData,
      location,
      userProfileData,
      userInfoFbPixel
    } = this.props;

    if (loggedIn) {
      this.checkAwaitingUserRedirect();
    }

    // Fetch all server popups after user is logged in
    if (loggedIn && !prevProps.loggedIn) {
      // this.props.getPopups();
      // Get token of chat for connect to chat room
      this.props.getTwilioToken();
    }

    if (this.checkPageNavigation(prevProps, location)) {
      matomoPageView(userData || null);
      // this.runOnUrlUpdatedOrLoad(userData, userProfileData, userInfoFbPixel, loggedIn);
    }
  }

  checkPageNavigation(prevProps, location) {
    return (
      prevProps.location &&
      location.pathname !== '/' &&
      location.pathname !== prevProps.location.pathname
    );
  }

  runOnUrlUpdatedOrLoad(userData, userProfileData, userInfoFbPixel, loggedIn) {
    const lpQueryStr = queryString.parse(
      localStorage.getItem(LpQueryStr.LOCAL_STORAGE_KEY)
    );

    let gender = null;

    if (!isEmpty(userProfileData)) {
      gender = userProfileData.user_gender;
    } else if (lpQueryStr) {
      gender = lpQueryStr.gender;
    }

    fbInit(gender);

    if (
      !isEmpty(userProfileData) &&
      !isEmpty(userData) &&
      !isEmpty(userInfoFbPixel) &&
      loggedIn
    ) {
      const { id } = userData;
      const { user_status, has_applied_subscription } = userProfileData;
      const fbUserData = {
        id,
        gender,
        user_status,
        teamMemberRate: userInfoFbPixel.team_member_rate,
        has_applied_subscription,
        hasAnnualIncomegte10Million:
          userInfoFbPixel.has_annual_income_gte_10_million
      };

      if (
        userInfoFbPixel.event_infos != null &&
        userInfoFbPixel.event_infos.length > 0
      ) {
        userInfoFbPixel.event_infos.forEach(eventInfo => {
          fbPixelTrackEvent(
            id,
            eventInfo.event_name,
            gender,
            eventInfo.event_id
          );
        });
      }

      this.checkToFirePixelPurchaseEvent(fbUserData);
      this.checkToFirePixelLeadEvent(fbUserData);
    }
  }

  // check to fire facebook pixel Lead event
  checkToFirePixelLeadEvent(userData) {
    if (userData && !isEmpty(userData)) {
      const { gender, user_status, teamMemberRate } = userData;

      // Render FB pixel for only approved women with team member rate 7 or more.
      if (
        gender == UserGender.FEMALE &&
        user_status == UserStatus.APPROVED_BY_SYSTEM &&
        !isNaN(teamMemberRate) &&
        teamMemberRate >= 7
      ) {
        const gtmId = window.__env__.GOOGLE_TAG_MANAGER_ID || gtmID;

        GTagManager.initialize({
          gtmId: gtmId,
          events: {
            conversion: {
              send_to: 'AW-762830684/vbiwCOvHi68BENy-3-sC',
              transaction_id: ''
            }
          }
        });
      }
    }
  }

  // fire facebook pixel Purchase event
  checkToFirePixelPurchaseEvent(userData) {
    const { gender, user_status, has_applied_subscription } = userData;
    // Render FB pixel for approved male paid user
    if (
      gender == UserGender.MALE &&
      user_status == UserStatus.APPROVED_BY_SYSTEM &&
      has_applied_subscription
    ) {
      const gtmId = window.__env__.GOOGLE_TAG_MANAGER_ID || gtmID;

      GTagManager.initialize({
        gtmId: gtmId,
        events: {
          conversion: {
            send_to: 'AW-762830684/vbiwCOvHi68BENy-3-sC',
            transaction_id: ''
          }
        }
      });
    }
  }

  checkAwaitingUserRedirect() {
    const pagesForAwaiting = [
      'registration-form',
      'participation',
      'dating-confirmation',
      'email-reservation',
      'match-info',
      'logout'
    ];
    if (
      this.props.userStatus <= UserStatus.AWAITING_SCREENING &&
      !pagesForAwaiting.includes(this.props.location.pathname.split('/')[1])
    ) {
      this.props.history.push('/participation');
    }
  }

  async requestUserProfile() {
    // await this.props.userProfile({});

    // Get token of chat for connect to chat room
    await this.props.getTwilioToken();
  }

  ensureAnalyticsCookie() {
    const userId = this.props.userData ? this.props.userData.id : null;
    if (
      userId &&
      (!cookies.get('bachelor_lkuid') ||
        cookies.get('bachelor_lkuid') !== userId)
    ) {
      const current = new Date();
      const nextYear = new Date();

      nextYear.setFullYear(current.getFullYear() + 1);

      cookies.set('bachelor_lkuid', userId, {
        path: '/',
        domain: '.bachelorapp.net',
        expires: nextYear
      });
    }
  }

  renderServerPopups = popup => {
    switch (popup.type_code) {
      case CHANGE_COST_PLAN_DOWNGRADE:
        return (
          <ServerPopupContainer
            key={popup.id}
            popup={popup}
            type={CHANGE_COST_PLAN_DOWNGRADE}
            onClick={res => {
              var myItems = [];
              var rawItems = res.message.split(/\\n/g);
              for (var i = 0; i < rawItems.length; i++) {
                myItems.push({ content: rawItems[i] });
              }
              this.setState({
                isShowSubPopup: 2,
                titleSubPopup: res.title,
                messageSubPopup: myItems
              });
            }}
          />
        );
      case CHANGE_COST_PLAN:
        return (
          <ServerPopupContainer
            key={popup.id}
            popup={popup}
            type={CHANGE_COST_PLAN}
          />
        );
      case COMPLETE_UPDATE_PROFILE:
        return (
          <ServerPopupContainer
            key={popup.id}
            popup={popup}
            type={COMPLETE_UPDATE_PROFILE}
          />
        );
      case CHANGE_COST_PLAN_UPGRADE:
        return (
          <ServerPopupContainer
            key={popup.id}
            popup={popup}
            type={CHANGE_COST_PLAN_UPGRADE}
            onClick={res => {
              var myItems = [];
              var rawItems = res.message.split(/\\n/g);
              for (var i = 0; i < rawItems.length; i++) {
                myItems.push({ content: rawItems[i] });
              }
              this.setState({
                isShowSubPopup: 1,
                titleSubPopup: res.title,
                messageSubPopup: myItems
              });
            }}
          />
        );
      default:
        return null;
    }
  };

  renderSubPopup = () => {
    const { t } = this.props;
    const { titleSubPopup, messageSubPopup } = this.state;
    switch (this.state.isShowSubPopup) {
      case 1:
        return (
          <Popup
            key="popup-done-changing-btn"
            visible={this.state.isShowSubPopup === 1}
            title={titleSubPopup}
            MessagesComponent={<MessageCustom messages={messageSubPopup} />}
            okButtonTitle={t('popup-done-changing-btn')}
            onOkClicked={() => {
              this.setState({ isShowSubPopup: 0 });
              this.props.history.push('/participation');
            }}
            onCancelClicked={() => {
              this.setState({ isShowSubPopup: 0 });
            }}
          />
        );
      case 2:
        return (
          <Popup
            key="popup-done-resevation"
            visible={this.state.isShowSubPopup === 2}
            title={titleSubPopup}
            MessagesComponent={<MessageCustom messages={messageSubPopup} />}
            okButtonTitle={t('popup-done-resevation-btn')}
            onOkClicked={() => {
              this.setState({ isShowSubPopup: 0 });
            }}
            onCancelClicked={() => {
              this.setState({ isShowSubPopup: 0 });
            }}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const isWhiteMode = JSON.parse(localStorage.getItem('isWhiteMode'));
    const overlay = {
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',
      zIndex: 50000,
      backgroundColor: 'rgba(0, 0, 0, 1)'
    };

    let routes = (
      <Switch>
        <Route exact path="/" component={AsyncHomeScreen} />
        <Route path="/invite-friends" component={AsyncInviteFriends} />
        <Route path="/feedback" component={AsyncFeedback} />
        <Route path="/chat/room" component={ChatFeature} />
        <Route path="/chat" component={ChatFeature} />
        <Route path="/coupon" component={AsyncCoupon} />
        <Route path="/coupon-exchange" component={AsyncCouponExchange} />
        <Route
          path="/cancellation-application"
          component={AsyncCancellationApplication}
        />
        <Route
          exact
          path="/12pm-rematching-application"
          component={AsyncRematchingApplication}
        />
        <Route path="/matchprofile" component={AsyncMatchProfile} />
        <Route path="/match-info" component={AsyncMatchProfileInfo} />
        <Route path="/profile" component={AsyncCurrentUserProfile} />
        <Route path="/partnerdetail" component={AsyncPartnerDetail} />
        <Route path="/participation" component={AsyncParticipation} />
        <Route path="/purchase-dating-coupon" component={AsyncTicketPurchase} />
        <Route path="/date-ticket" component={AsyncDateTicket} />
        <Route
          path="/dating-confirmation"
          component={AsyncDatingConfirmation}
        />
        <Route path="/participated-date" component={AsyncParticipatedDate} />
        <Route path="/evaluation" component={AsyncEvaluationInfo} />
        <Route exact path="/login" component={AsyncLoginPage} />
        <Route exact path="/sms-login" component={AsyncSMSLoginPage} />
        <Route
          exact
          path="/sms-verification-code"
          component={AsyncSMSVerificationPage}
        />
        <Route
          exact
          path="/trial-resumption"
          component={AsyncTrialResumption}
        />
        <Route exact path="/paid-resumption" component={AsyncPaidResumption} />
        <Route
          exact
          path="/resumption-package"
          component={AsyncResumptionPackage}
        />
        <Route
          exact
          path="/resumption-application"
          component={AsyncResumptionApplication}
        />
        <Route
          path="/sms-verification-code"
          component={AsyncSMSVerificationPage}
        />
        <Route
          exact
          path="/registration-form"
          component={AsyncRegistrationFormPage}
        />
        <Route path="/about-cancellation" component={AsyncAboutCancellation} />
        <Route
          path="/cancel-date-application"
          component={AsyncCancelDateApplication}
        />
        <Route path="/other" component={AsyncContact} />
        <Route
          exact
          path="/registration-form/step-0"
          component={AsyncRegistrationFormPage}
        />
        <Route
          exact
          path="/registration-form/step-1"
          component={AsyncStepOnePage}
        />
        <Route
          exact
          path="/2nd-registration-form/step-1"
          component={Async2ndStepOnePage}
        />
        <Route
          exact
          path="/registration-form/step-2"
          component={AsyncStepTwoPage}
        />
        <Route
          exact
          path="/2nd-registration-form/step-2"
          component={Async2ndStepTwoPage}
        />
        <Route
          exact
          path="/registration-form/step-3"
          component={AsyncStepThreePage}
        />
        <Route
          exact
          path="/2nd-registration-form/step-3"
          component={Async2ndStepThreePage}
        />
        <Route
          exact
          path="/registration-form/step-4"
          component={AsyncStepFourPage}
        />
        <Route
          exact
          path="/2nd-registration-form/step-4"
          component={Async2ndStepFourPage}
        />
        <Route
          exact
          path="/registration-form/step-5"
          component={AsyncStepFivePage}
        />
        <Route
          exact
          path="/2nd-registration-form/step-5"
          component={Async2ndStepFivePage}
        />
        <Route
          exact
          path="/2nd-registration-form/step-7"
          component={Async2ndStepSevenPage}
        />
        <Route
          exact
          path="/2nd-registration-form/step-8"
          component={Async2ndStepEightPage}
        />

        <Route
          exact
          path="/registration-form/step-6"
          component={AsyncStepSixPage}
        />
        <Route
          exact
          path="/registration-form/step-7"
          component={AsyncStepSevenPage}
        />
        <Route path="/about-cancellation" component={AsyncAboutCancellation} />
        <Route
          path="/cancel-date-application"
          component={AsyncCancelDateApplication}
        />
        <Route path="/other" component={AsyncContact} />
        <Route
          exact
          path="/2nd-registration-form/step-6"
          component={Async2ndStepSixPage}
        />
        <Route
          exact
          path="/2nd-registration-form/step-7"
          component={Async2ndStepSevenPage}
        />
        <Route exact path="/profile-detail" component={AsyncProfileDetail} />
        <Route exact path="/bachelor-page" component={AsyncBachelorPage} />
        <Route exact path="/email" component={AsyncEmailAddress} />
        <Route exact path="/add-user-card" component={AsyncAddUserCard} />
        <Route exact path="/last-step" component={AsyncLastStepAddCard} />
        <Route exact path="/slide-show" component={AsyncSlideShow} />
        <Route
          exact
          path="/profile-detail-partner"
          component={AsyncProfileDetailPartner}
        />
        <Route
          exact
          path="/email-registration"
          component={AsyncEmailRegistration}
        />
        <Route
          exact
          path="/email-reservation"
          component={AsyncEmailReservation}
        />
        <Redirect to="/" />
      </Switch>
    );
    if (this.props.loggedIn) {
      routes = (
        <Switch>
          <Route
            exact
            path="/subscription-paused"
            component={AsyncSubscriptionPaused}
          />
          <Route
            exact
            path="/subscription-paused-request"
            component={AsyncSubscriptionPausedRequest}
          />
          <Route path="/feedback" component={AsyncFeedback} />
          <Route path="/invite-friends" component={AsyncInviteFriends} />
          <Route path="/chat/room" component={ChatFeature} />
          <Route path="/chat" component={ChatFeature} />
          <Route path="/coupon" component={AsyncCoupon} />
          <Route path="/coupon-exchange" component={AsyncCouponExchange} />
          <Route
            exact
            path="/trial-resumption"
            component={AsyncTrialResumption}
          />
          <Route
            exact
            path="/paid-resumption"
            component={AsyncPaidResumption}
          />
          <Route
            exact
            path="/resumption-package"
            component={AsyncResumptionPackage}
          />
          <Route
            exact
            path="/resumption-application"
            component={AsyncResumptionApplication}
          />
          <Route
            path="/dating-confirmation"
            component={AsyncDatingConfirmation}
          />
          <Route
            exact
            path="/12pm-rematching-application"
            component={AsyncRematchingApplication}
          />
          <Route
            path="/cancel-date-application"
            component={AsyncCancelDateApplication}
          />
          <Route path="/evaluation" component={AsyncEvaluationInfo} />
          <Route path="/evaluations" component={AsyncEvaluationList} />
          <Route path="/matchprofile" component={AsyncMatchProfile} />
          <Route path="/match-info" component={AsyncMatchProfileInfo} />
          <Route path="/profile" component={AsyncCurrentUserProfile} />
          <Route path="/about-deactivation" component={AsyncAboutDeactive} />
          <Route
            path="/deactivation-application"
            component={AsyncAboutDeactiveApp}
          />
          <Route
            path="/about-cancellation"
            component={AsyncAboutCancellation}
          />
          <Route
            path="/cancellation-application"
            component={AsyncCancellationApplication}
          />
          <Route path="/partnerdetail" component={AsyncPartnerDetail} />
          <Route path="/login" component={AsyncLoginPage} />
          <Route path="/sms-login" component={AsyncSMSLoginPage} />
          <Route
            path="/sms-verification-code"
            component={AsyncSMSVerificationPage}
          />
          <Route
            exact
            path="/registration-form"
            component={AsyncRegistrationFormPage}
          />
          <Route
            exact
            path="/registration-form/step-0"
            component={AsyncRegistrationFormPage}
          />
          <Route
            exact
            path="/registration-form/step-1"
            component={AsyncStepOnePage}
          />
          <Route
            exact
            path="/registration-form/step-2"
            component={AsyncStepTwoPage}
          />
          <Route
            exact
            path="/registration-form/step-3"
            component={AsyncStepThreePage}
          />
          <Route
            exact
            path="/registration-form/step-4"
            component={AsyncStepFourPage}
          />
          <Route
            exact
            path="/registration-form/step-5"
            component={AsyncStepFivePage}
          />
          <Route
            exact
            path="/registration-form/step-6"
            component={AsyncStepSixPage}
          />
          <Route
            exact
            path="/registration-form/step-7"
            component={AsyncStepSevenPage}
          />
          <Route
            exact
            path="/2nd-registration-form/step-1"
            component={Async2ndStepOnePage}
          />
          <Route
            exact
            path="/2nd-registration-form/step-2"
            component={Async2ndStepTwoPage}
          />
          <Route
            exact
            path="/2nd-registration-form/step-3"
            component={Async2ndStepThreePage}
          />
          <Route
            exact
            path="/2nd-registration-form/step-4"
            component={Async2ndStepFourPage}
          />
          <Route
            exact
            path="/2nd-registration-form/step-5"
            component={Async2ndStepFivePage}
          />
          <Route
            exact
            path="/2nd-registration-form/step-6"
            component={Async2ndStepSixPage}
          />
          <Route
            exact
            path="/2nd-registration-form/step-7"
            component={Async2ndStepSevenPage}
          />
          <Route
            exact
            path="/2nd-registration-form/step-8"
            component={Async2ndStepEightPage}
          />
          <Route
            exact
            path="/email-registration"
            component={AsyncEmailRegistration}
          />
          <Route
            exact
            path="/email-reservation"
            component={AsyncEmailReservation}
          />
          <Route path="/logout" component={AsyncLogoutPage} />
          <Route path="/help" component={AsyncHelp} />
          <Route path="/contact" component={AsyncContact} />
          <Route path="/other" component={AsyncContact} />
          <Route path="/suggestion" component={AsyncLightSuggestion} />
          <Route
            path="/downgrade-suggestion"
            component={AsyncDowngradeSuggestion}
          />
          <Route
            path="/trial-extension-suggestion"
            component={AsyncTrialExtensionSuggestion}
          />
          <Route exact path="/" component={AsyncHomeScreen} />
          <Route path="/participation" component={AsyncParticipation} />
          <Route
            path="/purchase-dating-coupon"
            component={AsyncTicketPurchase}
          />
          <Route path="/date-ticket" component={AsyncDateTicket} />
          <Route path="/participated-date" component={AsyncParticipatedDate} />
          <Route exact path="/profile-detail" component={AsyncProfileDetail} />
          <Route exact path="/email" component={AsyncEmailAddress} />
          <Route exact path="/add-user-card" component={AsyncAddUserCard} />
          <Route exact path="/last-step" component={AsyncLastStepAddCard} />
          <Route exact path="/slide-show" component={AsyncSlideShow} />
          <Route exact path="/bachelor-page" component={AsyncBachelorPage} />
          <Route
            exact
            path="/profile-detail-partner"
            component={AsyncProfileDetailPartner}
          />
          <Redirect to="/" />
        </Switch>
      );
    }
    // {this.props.isRepair && <div style={{flex: 1, position: 'absolute', backgroundColor: 'red', zIndex:90000}}></div> }
    return (
      <>
        <MediaQuery minWidth={DEVICE_WIDTH}>
          <WebTemplateMobileSimulator>
            <DrawerMenu>
              {routes}
              <BottomTabBar history={this.props.history} />
              <LoadingSpinner isWhiteMode={isWhiteMode} />
              <ErrorPopup />
              {this.props.isRepair && (
                <div style={overlay}>
                  <AsyncServerRepair />
                </div>
              )}

              <PopupPortal>
                {(this.props.popups || []).map(this.renderServerPopups)}
              </PopupPortal>
              {this.state.isShowSubPopup !== 0 ? this.renderSubPopup() : null}
            </DrawerMenu>
          </WebTemplateMobileSimulator>
        </MediaQuery>
        <MediaQuery maxWidth={DEVICE_WIDTH}>
          <DrawerMenu>
            {routes}
            <BottomTabBar history={this.props.history} />
            <LoadingSpinner isWhiteMode={isWhiteMode} />
            <ErrorPopup />
            {this.props.isRepair && (
              <div style={overlay}>
                <AsyncServerRepair />
              </div>
            )}

            <PopupPortal>
              {(this.props.popups || []).map(this.renderServerPopups)}
            </PopupPortal>
            {this.state.isShowSubPopup !== 0 ? this.renderSubPopup() : null}
          </DrawerMenu>
        </MediaQuery>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    myProfile: path(['userProfile', 'data'], state),
    homescreen: state.homescreen,
    loggedIn: state.auth.loggedIn,
    isLoading: state.app.isLoading,
    userData: path(['auth', 'userdata', 'userAuth', 'user'], state),
    isRepair: state.servermaintain.isRepair,
    userStatus: path(['userGlobalInfo', 'data', 'user_status'], state),
    planPrice: path(['auth', 'userdata', 'plan_price'], state),
    userProfileData: path(['userGlobalInfo', 'data'], state),
    userInfoFbPixel: path(['userInfoFacebookPixel', 'data'], state),
    firstInitFbPixelGender: path(
      ['userInfoFacebookPixel', 'firstInitGender'],
      state
    ),
    popups: state.popups.data
  };
};

export default compose(
  withNamespaces('common', { wait: true }),
  withRouter,
  connect(mapStateToProps, {
    getPopups,
    userProfile,
    getMatchDetail,
    requestUserGlobalInfo,
    requestUserInfoFacebookPixel,
    getTwilioToken,
    hideLoading
  }),
  withTracker
)(App);
