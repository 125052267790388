import React from 'react';
import { Drawer } from 'antd-mobile';
import styles from './index.module.css';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { openDrawer, closeDrawer } from '@redux/actions';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { path } from 'ramda';
import { Colors, UserGender } from '../../constants/constant';
import { getZendeskFaqUrl } from '@helper/';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

const itemMenuHeart = require('@assets/item_menu_heart.png');
const itemMenuHelp = require('@assets/item_menu_help.png');
const itemMenuOther = require('@assets/other.png');
const itemMenuFooter = require('@assets/item_menu_footer.png');
const itemMenuPresent = require('@assets/present.png');
const itemMenuFooterWhite = require('@assets/logo_white.png');

class DrawerMenu extends React.Component {
  state = {
    open: false
  };
  onOpenChange = (...args) => {
    if (this.props.isDrawerOpen) {
      this.props.closeDrawer();
    } else {
      this.props.openDrawer();
    }
  };

  onClickDatingFlow = () => {
    window.open('https://wi.bachelorapp.net/new-new-flow-female');
    this.props.closeDrawer();
  };

  onClickHelp = () => {
    this.props.closeDrawer();
    this.props.history.push('/help');
  };

  onClickToOther = () => {
    this.props.closeDrawer();
    this.props.history.push('/other');
  };

  onClickFAQ = () => {
    this.props.closeDrawer();
    const zendeskUrl = getZendeskFaqUrl(this.props.userGender);
    window.open(zendeskUrl);
  };

  onClickInviteFriends = () => {
    this.props.closeDrawer();
    this.props.history.push('/invite-friends');
  };
  render() {
    const { t } = this.props;
    const isWhiteMode = JSON.parse(localStorage.getItem('isWhiteMode'));
    const background = isWhiteMode
      ? Colors.PRIMARY_COLOR
      : `linear-gradient(186.81deg, rgba(223, 185, 99, 0.63) 20.71%, #B3985A 49.37%, #3A311C 91.3%), rgba(192, 167, 110, 0.5)`;
    const backgroundColor = isWhiteMode ? Colors.PRIMARY_COLOR : '#C0A76E';
    const sidebar = (
      <div className={styles.sidebar}>
        <div className={styles.menuItem} onClick={this.onClickInviteFriends}>
          <img src={itemMenuPresent} alt="" className={styles.menuItemIcon} />
          <div className={styles.menuItemText}>
            {this.props.userGender !== UserGender.MALE
              ? t('drawer-item-invite-friends')
              : t('drawer-item-invite-friends-male')}
          </div>
        </div>
        {this.props.userGender !== UserGender.MALE && (
          <div className={styles.menuItem} onClick={this.onClickDatingFlow}>
            <img src={itemMenuHeart} alt="" className={styles.menuItemIcon} />
            <div className={styles.menuItemText}>{t('drawer-item-1')}</div>
          </div>
        )}
        <div className={styles.menuItem} onClick={this.onClickFAQ}>
          <img src={itemMenuHelp} alt="" className={styles.menuItemIcon} />
          <div className={styles.menuItemText}>{t('drawer-item-2')}</div>
        </div>
        <div className={styles.menuItem} onClick={this.onClickToOther}>
          <img src={itemMenuOther} alt="" className={styles.menuItemIcon} />
          <div className={styles.menuItemText}>{t('drawer-item-3')}</div>
        </div>
        <div className={styles.footer}>
          <div
            className={classNames(styles.line, {
              [styles.bgWhite]: isWhiteMode
            })}
          />

          <div className={styles.contentFooter}>
            <div className={styles.leftFooter}>
              <div
                className={classNames(styles.textFooter1, {
                  [styles.textWhite]: isWhiteMode
                })}
              >
                バチェラーデート
              </div>
              <Trans
                className={classNames(styles.textFooter2, {
                  [styles.textWhite]: isWhiteMode
                })}
                parent="div"
                i18nKey="drawer-footer-text"
              >
                <span className={styles.specificChar1}>0</span>
                <span className={styles.specificChar2}>1</span>
              </Trans>
            </div>
            <div className={styles.rightFooter}>
              <img
                src={isWhiteMode ? itemMenuFooterWhite : itemMenuFooter}
                alt=""
                className={styles.menuItemFooter}
              />
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div style={{ height: '100%', zIndex: 9999999, position: 'relative' }}>
        <Drawer
          className="my-drawer"
          contentStyle={{
            height: '100%'
            // zIndex: 100000
          }}
          sidebarStyle={{
            zIndex: 100000,
            height: '100%',
            background: background,
            backgroundColor: backgroundColor,
            width: '80%'
          }}
          overlayStyle={{
            zIndex: 2224
          }}
          sidebar={sidebar}
          onOpenChange={this.onOpenChange}
          open={this.props.isDrawerOpen}
        >
          {this.props.children}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isDrawerOpen: state.drawer.isDrawerOpen,
    userGender: path(['userGlobalInfo', 'data', 'user_gender'], state)
  };
};
export default compose(
  withNamespaces('drawer', { wait: true }),
  withRouter,
  connect(mapStateToProps, {
    openDrawer: openDrawer,
    closeDrawer: closeDrawer
  })
)(DrawerMenu);
