import * as types from '../actions/types';

const initialState = {
  dataMatch: {},
  matchDetail: {},
  isError: false,
  isRefresh: false,
  expiredAt: null
};

const matchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_RESET_MATCH_DATA:
      return {
        ...state,
        expiredAt: null,
        dataMatch: {}
      };
    case types.ADD_REQUEST_TIME_CHANGE_REQUESTED:
    case types.UPDATE_REQUEST_TIME_CHANGE_REQUESTED:
    case types.GET_MATCH_PROFILE_DETAIL_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.GET_MATCH_PROFILE_DETAIL_SUCCEEDED:
      return {
        ...state,
        matchDetail: action.data.data || {},
        isError: false,
        error: null
      };
    case types.GET_MATCH_PROFILE_DETAIL_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.CURRENT_USER_GET_MATCH_DATA_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.CURRENT_USER_GET_MATCH_DATA_SUCCEEDED:
      const expiredTime = new Date();
      expiredTime.setMinutes(expiredTime.getMinutes() + 10); // Set Expired time match data in 10 mins
      return {
        ...state,
        isError: false,
        // dataMatch: formatMatchProfile(action.data.data) || {},
        dataMatch: action.data.data || {},
        error: null,
        expiredAt: expiredTime.toString()
      };
    case types.ADD_REQUEST_TIME_CHANGE_SUCCEEDED:
    case types.UPDATE_REQUEST_TIME_CHANGE_SUCCEEDED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.CANCEL_FOR_REMATCH_REQUESTED:
      return {
        ...state,
        isError: false,
        error: null
      };
    case types.CANCEL_FOR_REMATCH_SUCCEEDED:
      return {
        ...state,
        dataMatch: {},
        isError: false,
        error: null
      };
    case types.CANCEL_FOR_REMATCH_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.ADD_REQUEST_TIME_CHANGE_FAILED:
    case types.UPDATE_REQUEST_TIME_CHANGE_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.CURRENT_USER_GET_MATCH_DATA_FAILED:
      return {
        ...state,
        dataMatch: {},
        isError: true,
        error: action.error
      };
    case types.CANCEL_DATE_BY_PARTNER_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.CANCEL_DATE_BY_PARTNER_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.CANCEL_DATE_BY_PARTNER_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.PARTICIPATE_REMATCH_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.SET_PARTICIPATION_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.PARTICIPATE_REMATCH_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default matchReducer;
