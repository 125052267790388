import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* getMatchData(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.MATCH_PROFILE,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CURRENT_USER_GET_MATCH_DATA_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.CURRENT_USER_GET_MATCH_DATA_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CURRENT_USER_GET_MATCH_DATA_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getMatchProfileDetail(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.MATCH_PROFILE + `/${action.params.id}`,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_MATCH_PROFILE_DETAIL_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_MATCH_PROFILE_DETAIL_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_MATCH_PROFILE_DETAIL_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* getCurrentServerTime(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.API_GET_CURRENT_SERVER_TIME,
      API.GET,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.GET_CURRENT_SERVER_TIME_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({
        type: types.GET_CURRENT_SERVER_TIME_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.GET_CURRENT_SERVER_TIME_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchGetMatchData() {
  yield takeLatest(types.CURRENT_USER_GET_MATCH_DATA_REQUESTED, getMatchData);
}

function* addRequestTimeChange(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.ADD_REQUEST_TIME,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.ADD_REQUEST_TIME_CHANGE_SUCCEEDED
      });
    } else {
      yield put({
        type: types.ADD_REQUEST_TIME_CHANGE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.ADD_REQUEST_TIME_CHANGE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* cancelDateByPartner(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.CANCELLED_BY_PARTNER,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CANCEL_DATE_BY_PARTNER_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.CANCEL_DATE_BY_PARTNER_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CANCEL_DATE_BY_PARTNER_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* participateRematch(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.PARTICIPATE_REMATCHING,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.PARTICIPATE_REMATCH_SUCCEEDED,
        data: response.data
      });
    } else {
      yield put({ type: types.PARTICIPATE_REMATCH_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.PARTICIPATE_REMATCH_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* cancelForRematch(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.CANCELLED_FOR_REMATCH,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CANCEL_FOR_REMATCH_SUCCEEDED
      });
    } else {
      yield put({
        type: types.CANCEL_FOR_REMATCH_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.CANCEL_FOR_REMATCH_FAILED, error });
  }
  store.dispatch(hideLoading());
}

function* updateRequestTimeChange(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(
      requestAPI,
      API.UPDATE_REQUEST_TIME,
      API.POST,
      action.params
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.UPDATE_REQUEST_TIME_CHANGE_SUCCEEDED
      });
    } else {
      yield put({
        type: types.UPDATE_REQUEST_TIME_CHANGE_FAILED,
        error: response
      });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_REQUEST_TIME_CHANGE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchCancelForRematch() {
  yield takeLatest(types.CANCEL_FOR_REMATCH_REQUESTED, cancelForRematch);
}

export function* watchCancelDateByPartner() {
  yield takeLatest(types.CANCEL_DATE_BY_PARTNER_REQUESTED, cancelDateByPartner);
}

export function* watchParticipateRematch() {
  yield takeLatest(types.PARTICIPATE_REMATCH_REQUESTED, participateRematch);
}

export function* watchAddRequestTimeChange() {
  yield takeLatest(
    types.ADD_REQUEST_TIME_CHANGE_REQUESTED,
    addRequestTimeChange
  );
}

export function* watchUpdateRequestTimeChange() {
  yield takeLatest(
    types.UPDATE_REQUEST_TIME_CHANGE_REQUESTED,
    updateRequestTimeChange
  );
}

export function* watchGetMatchProfileDetail() {
  yield takeLatest(
    types.GET_MATCH_PROFILE_DETAIL_REQUESTED,
    getMatchProfileDetail
  );
}

export function* watchGetCurrentServerTime() {
  yield takeLatest(
    types.GET_CURRENT_SERVER_TIME_REQUESTED,
    getCurrentServerTime
  );
}
