import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* onChangeCostPlan(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(requestAPI, API.CONFIRM_CHANGE_COST_PLAN, API.POST);

    yield put({
      type: types.CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED
    });

    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: types.CURRENT_USER_CHANGE_COST_PLAN_FAILED
    });
  }
  store.dispatch(hideLoading());
}

export function* watchChangeCostPlan() {
  yield takeLatest(
    types.CURRENT_USER_CHANGE_COST_PLAN_REQUESTED,
    onChangeCostPlan
  );
}
function* onCancelChangeCostPlan(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    response = yield call(requestAPI, API.CANCEL_CHANGE_COST_PLAN, API.POST);
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    console.log(error);
  }
  store.dispatch(hideLoading());
}

export function* watchCancelChangeCostPlan() {
  yield takeLatest(
    types.CURRENT_USER_CANCEL_COST_PLAN_REQUESTED,
    onCancelChangeCostPlan
  );
}
