import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import { store } from '../store';
import { hideLoading, showLoading } from '../actions';

function* useImage(action) {
  let response = null;
  store.dispatch(showLoading());
  try {
    // image post data
    response = yield call(
      requestAPI,
      API.USE_IMAGE,
      API.POST,
      action.params.data
    );

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.STORE_IMAGE_SUCCEDDED, data: response.data });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({ type: types.STORE_IMAGE_FAILED, error: response });
    } else {
      yield put({ type: types.STORE_IMAGE_FAILED, error: response });
    }
    if (action.onComplete) {
      action.onComplete(response);
    }
  } catch (error) {
    yield put({ type: types.STORE_IMAGE_FAILED, error });
  }
  store.dispatch(hideLoading());
}

export function* watchUseImage() {
  yield takeLatest(types.STORE_IMAGE_REQUESTED, useImage);
}
